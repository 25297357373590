import { CheckCircle, DeleteOutline, MoreVert } from '@mui/icons-material';
import { ListItemIcon, ListItemText } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useState } from 'react';
import { DeliveryAddress } from '../../../../../shared/entities/delivery_address';

type Props = {
	address: DeliveryAddress;
	onSelectAsMain: () => void;
	onDelete: () => void;
	onEdit: () => void;
};

function DeliveryAddressPopupMenu({
	address,
	onSelectAsMain,
	onDelete,
	onEdit,
}: Props) {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event: any) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton onClick={handleClick}>
				<MoreVert />
			</IconButton>
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
				{!address.isMain && (
					<MenuItem
						onClick={() => {
							handleClose();
							onSelectAsMain();
						}}
						style={{ width: 210, marginBottom: '8px' }}
					>
						<ListItemIcon>
							<CheckCircle fontSize="small" />
						</ListItemIcon>
						<ListItemText>Definir como principal</ListItemText>
					</MenuItem>
				)}
				{/* <MenuItem
					onClick={() => {
						handleClose();
						onEdit();
					}}
					style={{ width: 210, marginBottom: '8px' }}
				>
					<ListItemIcon>
						<Edit fontSize="small" />
					</ListItemIcon>
					<ListItemText>Editar</ListItemText>
				</MenuItem> */}
				<MenuItem
					onClick={() => {
						handleClose();
						onDelete();
					}}
					style={{ width: 210 }}
				>
					<ListItemIcon>
						<DeleteOutline fontSize="small" />
					</ListItemIcon>
					<ListItemText>Excluir</ListItemText>
				</MenuItem>
			</Menu>
		</>
	);
}

export default DeliveryAddressPopupMenu;
