import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Typography,
} from '@mui/material';

export function EstablishmentClosedDialog({
	open,
	close,
}: {
	open: boolean;
	close: () => void;
}) {
	return (
		<Dialog open={open} onClose={close}>
			<DialogTitle>Estabelecimento fechado</DialogTitle>
			<DialogContent>
				<Typography>
					Você pode continuar navegando pelo cardápio, mas não será possível
					fazer um pedido.
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => close()}>Ok</Button>
			</DialogActions>
		</Dialog>
	);
}
