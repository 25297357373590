import { Card, Typography } from '@mui/material';

export const UnavailableDeliveryCard = () => {
	return (
		<Card
			variant="outlined"
			style={{
				padding: '20px',
				marginTop: '20px',
				backgroundColor: '#e06363',
			}}
		>
			<Typography variant="h6">Entrega indisponível</Typography>
			<Typography color="white">
				O estabelecimento não realiza entregas no seu endereço
			</Typography>
		</Card>
	);
};
