import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryButton } from '../../../../shared/components';
import { Loading } from '../../../../shared/components/Loading';
import { UserAppBar } from '../../../../shared/components/UserAppBar';
import { AppError } from '../../../../shared/errors';
import { DeliveryAddressForm } from '../components/DeliveryAddressForm';
import { useDeliveryAddress } from '../contexts/DeliveryAddressContext';

type Props = {};

export function EditDeliveryAddressPage() {
	const navigate = useNavigate();
	const id = useParams().id as unknown as number;
	const [isLoading, setIsLoading] = useState(true);
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [error, setError] = useState<AppError | null>(null);
	const { state, actions } = useDeliveryAddress();

	useEffect(() => {
		// setIsLoading(true);
		// userDeliveryAddressRepository
		// 	.getDeliveryAddress(id)
		// 	.then(actions.fromEntity)
		// 	.finally(() => setIsLoading(false));
	}, []);

	async function save() {
		if (isSubmitting) return;
		if (!actions.validate()) return;
		setIsSubmitting(true);
		try {
			// await userDeliveryAddressRepository.updateDeliveryAddress(
			// 	actions.toEntity()
			// );
			navigate(-1);
		} catch (error) {
			setError(error as AppError);
		} finally {
			setIsSubmitting(false);
		}
	}

	if (isLoading) {
		return <Loading />;
	}

	return (
		<>
			<UserAppBar title="Editar endereço" />
			<Box padding="20px">
				<DeliveryAddressForm />
			</Box>
			<Box
				sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
				height="60px"
				padding="50px 20px"
			>
				<PrimaryButton label="Salvar" onClick={save} isLoading={isSubmitting} />
			</Box>
		</>
	);
}
