import { Box, Typography } from '@mui/material';
import { AdditionalGroup } from '../../../shared/entities';
import { useProduct } from '../ProductContext';

export function AdditionalGroupHeader({ group }: { group: AdditionalGroup }) {
	return (
		<Box bgcolor="grey.100" padding="15px" borderRadius="10px">
			<Typography variant="h6">{group.name}</Typography>
			{group.isSingleChoice && <SingleChoiceHeader group={group} />}
			{!group.isSingleChoice && <MultipleChoiceHeader group={group} />}
		</Box>
	);
}

function SingleChoiceHeader({ group }: { group: AdditionalGroup }) {
	const { totalAdditionalGroupSelected } = useProduct();
	const selected = totalAdditionalGroupSelected(group);
	return (
		<Box bgcolor="grey.100" borderRadius="10px">
			<Box
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
			>
				<Typography variant="subtitle2">{selected} de 1</Typography>
				{group.required && <RequiredLabel />}
			</Box>
		</Box>
	);
}

function MultipleChoiceHeader({ group }: { group: AdditionalGroup }) {
	return (
		<Box bgcolor="grey.100" borderRadius="10px">
			{group.min > 0 && (
				<Box
					display="flex"
					flexDirection="row"
					justifyContent="space-between"
					alignItems="center"
				>
					<Typography variant="subtitle2">
						Escolha de {group.min} a {group.max} adicionais
					</Typography>
					<RequiredLabel />
				</Box>
			)}
			{group.min <= 0 && (
				<Typography variant="subtitle2">
					Escolha até {group.max} {group.max === 1 ? 'adicional' : 'adicionais'}
				</Typography>
			)}
		</Box>
	);
}

function RequiredLabel() {
	return (
		<Box
			sx={{
				backgroundColor: 'grey.900',
				borderRadius: '4px',
				padding: '1px 8px',
			}}
		>
			<Typography variant="subtitle2" style={{ color: 'white' }}>
				Obrigatório
			</Typography>
		</Box>
	);
}
