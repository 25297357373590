import { TextField } from '@mui/material';
import { UserMaxLength } from '../entities';

export function NameInput({
	value,
	error,
	onChange,
	style,
}: {
	value: string;
	error: string;
	onChange: (value: string) => void;
	style?: any;
}) {
	return (
		<TextField
			fullWidth={true}
			label="Como podemos te chamar? *"
			placeholder="Digite seu nome"
			error={error?.trim().length > 0}
			helperText={error?.trim()}
			variant="standard"
			value={value}
			style={style}
			onChange={(e) => onChange(e.target.value)}
			inputProps={{
				maxLength: UserMaxLength.name,
			}}
		/>
	);
}
