declare global {
	export interface Date {
		formatHourMinute(): string;
	}
}

// eslint-disable-next-line
Date.prototype.formatHourMinute = function (this: Date) {
	return new Intl.DateTimeFormat('pt-BR', {
		hour: '2-digit',
		minute: '2-digit',
	}).format(this);
};

export {};
