import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';

export function ConfirmationDialog({
	title,
	body,
	confirmText,
	open,
	setOpen,
	onConfirm,
	onCancel,
}: {
	title: string;
	body: string;
	confirmText?: string;
	open: boolean;
	setOpen: (open: boolean) => void;
	onConfirm: () => void;
	onCancel?: () => void;
}) {
	const handleConfirm = () => {
		setOpen(false);
		onConfirm();
	};

	const handleCancel = (_: any, reason?: string) => {
		if (reason === 'backdropClick') return;
		setOpen(false);
		onCancel?.();
	};

	return (
		<Dialog open={open} onClose={handleCancel}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>{body}</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel}>Cancelar</Button>
				<Button onClick={handleConfirm}>{confirmText ?? 'Confirmar'}</Button>
			</DialogActions>
		</Dialog>
	);
}
