import { City } from '../../shared/entities/delivery_address';
import { DeliveryConfig } from '../../shared/entities/delivery_config';
import {
	DeliveryDistrict,
	District,
	Region,
} from '../../shared/entities/district';
import { DeliveryError, deliveryErrors } from './delivery_errors';

type Props = {
	config: DeliveryConfig;
	districts: DeliveryDistrict[];
	address: { city: City; neighborhood: string; region?: string | null };
	bagTotal: number;
};

type Result = {
	error?: DeliveryError;
	deliveryFee?: number;
	minOrderValue?: number;
	regions?: Region[];
};

export const getDeliveryFromDistrict = (
	district: District,
	bagTotal: number,
	config: DeliveryConfig
): Result | null => {
	if (district.isUnregistered) {
		return {
			deliveryFee: config.defaultDeliveryFee,
			minOrderValue: config.defaultMinimumOrderValue,
			error:
				bagTotal < config.defaultMinimumOrderValue
					? deliveryErrors.minOrderValue(config.defaultMinimumOrderValue)
					: undefined,
		};
	}

	if (district.hasDelivery) {
		return {
			deliveryFee: district.deliveryFee!,
			minOrderValue: district.minOrderValue!,
			error:
				bagTotal < district.minOrderValue!
					? deliveryErrors.minOrderValue(district.minOrderValue!)
					: undefined,
		};
	}

	if (!district.regions?.length) {
		return { error: deliveryErrors.unavailableDeliveryOnNeighborhood };
	}

	return null;
};

export const getDeliveryAvailability = (props: Props): Result => {
	const { config, districts, address, bagTotal } = props;

	const district = districts.find((item) =>
		deepStrCompare(item.city.name, address.city.name)
	);

	if (!district && config.acceptOrderFromDifferentCity) {
		return {
			deliveryFee: config.defaultDeliveryFee,
			minOrderValue: config.defaultMinimumOrderValue,
		};
	}

	if (!district) {
		return { error: deliveryErrors.unavailableDeliveryOnCity };
	}

	const neighborhood = district.districts.find((item) =>
		deepStrCompare(item.neighborhood, address.neighborhood)
	);

	if (!neighborhood) {
		if (!config.deliverOnUnregisteredRegion) {
			return { error: deliveryErrors.unavailableDeliveryOnNeighborhood };
		}
		return {
			deliveryFee: config.defaultDeliveryFee,
			minOrderValue: config.defaultMinimumOrderValue,
			error:
				bagTotal < config.defaultMinimumOrderValue
					? deliveryErrors.minOrderValue(config.defaultMinimumOrderValue)
					: undefined,
		};
	}

	if (neighborhood.regions?.length) {
		let region: Region | undefined = undefined;
		if (address.region) {
			region = neighborhood.regions.find((item) =>
				deepStrCompare(item.name, address.region!)
			);
		}
		if (!region) {
			return {
				error: deliveryErrors.requireRegion,
				regions: neighborhood.regions,
			};
		}
		return getDeliveryFromRegion(region, bagTotal);
	}

	const { hasDelivery, deliveryFee, minOrderValue } = neighborhood;
	if (!hasDelivery) {
		return { error: deliveryErrors.unavailableDeliveryOnNeighborhood };
	}
	return { deliveryFee, minOrderValue };
};

export const getDeliveryFromRegion = (
	region: Region,
	bagTotal: number
): Result => {
	if (!region.hasDelivery) {
		return { error: deliveryErrors.unavailableDeliveryOnRegion };
	}
	return {
		deliveryFee: region.deliveryFee,
		minOrderValue: region.minOrderValue,
		error:
			bagTotal < region.minOrderValue
				? deliveryErrors.minOrderValue(region.minOrderValue)
				: undefined,
	};
};

const deepStrCompare = (a: string, b: string) =>
	a.removeDiacritics().toLowerCase() === b.removeDiacritics().toLowerCase();
