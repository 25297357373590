import { SessionExpiredError } from '../../shared/errors';
import { Config } from '../entities/config';
import { DeliveryConfig } from '../entities/delivery_config';
import { DeliveryDistrict } from '../entities/district';
import { HttpClient } from '../http_client';
import { BaseRepository } from './base_repository';

export class EstablishmentRepository extends BaseRepository {
	constructor(private httpClient: HttpClient) {
		super();
	}

	async isOpened(): Promise<boolean> {
		const session = this.getSession();
		if (!session || session.isExpired) throw new SessionExpiredError();
		const path = `/establishments/${session.establishment.uuid}/status`;
		const json = await this.httpClient.get(path);
		return json['open'];
	}

	async isOnline(): Promise<boolean> {
		const session = this.getSession();
		if (!session || session.isExpired) throw new SessionExpiredError();
		const path = `/establishments/${session.establishment.uuid}/system-status`;
		const json = await this.httpClient.get(path);
		return json['online'];
	}

	async getConfig(): Promise<Config> {
		const session = this.getSession();
		if (!session || session.isExpired) throw new SessionExpiredError();
		const path = `/establishments/${session.establishment.uuid}/config`;
		const json = await this.httpClient.get(path);
		return Config.fromJson(json);
	}

	async getDeliveryDistrics(): Promise<DeliveryDistrict[]> {
		const session = this.getSession();
		if (!session || session.isExpired) throw new SessionExpiredError();
		const path = `/establishments/${session.establishment.uuid}/delivery-districts`;
		const json = await this.httpClient.get(path);
		return json.map(DeliveryDistrict.fromJson);
	}

	async getDeliveryConfig(): Promise<DeliveryConfig> {
		const session = this.getSession();
		if (!session || session.isExpired) throw new SessionExpiredError();
		const path = `/establishments/${session.establishment.uuid}/delivery-config`;
		const json = await this.httpClient.get(path);
		return DeliveryConfig.fromJson(json);
	}
}
