declare global {
	export interface String {
		removeDiacritics(): string;
		onlyNumbers(): string;
		toCellPhone(): string;
		toZipCode(): string;
		toDocument(): string;
	}
}

// eslint-disable-next-line
String.prototype.removeDiacritics = function (this: string) {
	return this.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

// eslint-disable-next-line
String.prototype.onlyNumbers = function (this: string) {
	return this.replace(/\D/g, '');
};

// eslint-disable-next-line
String.prototype.toCellPhone = function (this: string) {
	if (this.length > 13) return this;
	if (this.length === 10) {
		return this.replace(/^(\d{2})(\d{4})(\d{4})$/, '($1) $2-$3');
	}
	let phone = this;
	if (this.length === 13 && this.startsWith('55')) {
		phone = this.substring(2);
	}
	return phone.replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3');
};

// eslint-disable-next-line
String.prototype.toZipCode = function (this: string) {
	return this.replace(/^(\d{5})(\d{3})$/, '$1-$2');
};

// eslint-disable-next-line
String.prototype.toDocument = function (this: string) {
	if (this.length > 14) return this;
	if (this.length === 11) {
		return this.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4');
	}
	return this.replace(
		/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
		'$1.$2.$3/$4-$5'
	);
};

export {};
