import { Box, Button, Typography } from '@mui/material';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isAndroid, isIOS } from './device_detector';
import { isWebView } from './wv_detector';

type Props = {
	children: ReactNode;
};

// O redirecionamento é desativado para iOS, pois não foi possível encontrar uma forma de redirecionar para o navegador no iOS.
// Como o iOS não tem suporte a push notifications, não é necessário abrir o app em um navegador. O app pode abrir numa webview.
const redirectEnabled = isWebView() && !isIOS();

export const WebViewRedirect = ({ children }: Props) => {
	const location = useLocation();
	const [timeToRedirect, setTimeToRedirect] = useState(3);
	const intervalRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		if (redirectEnabled) {
			intervalRef.current = setInterval(
				() => setTimeToRedirect((prev) => prev - 1),
				1000
			);
			return () => clearInterval(intervalRef.current!);
		}
	}, []);

	useEffect(() => {
		if (timeToRedirect === 0) {
			clearInterval(intervalRef.current!);
			openBrowser();
		}
	}, [timeToRedirect]);

	const openBrowser = () => {
		const searchParams = new URLSearchParams(location.search);
		const token = searchParams.get('token') || '';
		const url = `${window.location.host}?token=${token}`;
		let href = '';
		if (isAndroid()) {
			href = `intent://${url}#Intent;scheme=https;end`;
		}
		// Em testes vimos que no samsung browser, em android <= 8.1, o _self não funciona recarrega a página atual. Então foi necessário usar _blank.
		// Porém, em outros navegadores, o _blank não funciona. Então, foi necessário usar _self para esses casos.
		const newWindow = window.open(href, '_blank');
		if (!newWindow) {
			window.open(href, '_self');
		}
	};

	if (!redirectEnabled) {
		return <>{children}</>;
	}

	return (
		<Box
			display="flex"
			flexDirection="column"
			alignItems="center"
			justifyContent="center"
			marginTop="64px"
			padding="0 18px"
		>
			<Typography variant="h6" align="center">
				Para uma melhor experiência, estamos redirecionando você para o
				navegador.
			</Typography>
			<Typography sx={{ marginTop: '12px' }} variant="body1" align="center">
				Redirecionando para o navegador em {timeToRedirect} segundos...
			</Typography>
			<Button sx={{ marginTop: '12px' }} onClick={openBrowser}>
				Abrir no navegador agora
			</Button>
		</Box>
	);
};
