import { createTheme } from '@mui/material';

export const theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: '#e03131',
		},
		secondary: {
			main: '#2b2d2b',
		},
		error: {
			main: '#e03131',
		},
		grey: {
			'200': '#f4f4f4',
			'900': '#2b2d2b',
		},
		background: {
			paper: '#fff',
			default: '#fff',
		},
		text: {
			primary: '#2b2d2b',
		},
		divider: '#e3e6e3',
	},
	typography: {
		h5: {
			fontSize: '1.1rem',
			color: '#2b2d2b',
			fontWeight: 'bold',
		},
		h6: {
			fontSize: '1rem',
			color: '#2b2d2b',
			fontWeight: 'bold',
		},
		subtitle1: {
			fontSize: '1rem',
			color: '#2b2d2b',
		},
		subtitle2: {
			fontSize: '0.9rem',
			color: '#2b2d2b',
		},
		body1: {
			fontSize: '0.9rem',
			color: '#6b6969',
		},
		body2: {
			fontSize: '0.8rem',
			color: '#9E9E9E',
		},
	},
	components: {
		MuiAppBar: {
			defaultProps: {
				color: 'inherit',
				elevation: 0,
				position: 'sticky',
				style: {
					backgroundColor: '#fff',
					color: '#2b2d2b',
				},
			},
		},
		MuiTextField: {
			defaultProps: {
				variant: 'outlined',
				color: 'primary',
				style: {
					color: '#2b2d2b',
				},
			},
		},
	},
});
