import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { Divider, IconButton } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { theme } from '../theme';

type Props = {
	title: string;
	actions?: React.ReactNode;
};

export function UserAppBar({ title, actions }: Props) {
	const navigate = useNavigate();
	const location = useLocation();

	function goBack() {
		navigate(-1);
	}

	return (
		<>
			<AppBar
				sx={{
					top: 0,
					height: '75px',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Toolbar>
					{location.pathname !== '/' && (
						<IconButton
							style={{
								color: theme.palette.text.primary,
								marginRight: '10px',
							}}
							onClick={goBack}
						>
							<KeyboardBackspaceOutlinedIcon />
						</IconButton>
					)}
					<Typography
						variant="h6"
						component="div"
						sx={{ flexGrow: 1 }}
						fontWeight="bold"
					>
						{title}
					</Typography>
					{actions}
				</Toolbar>
				<Divider />
			</AppBar>
		</>
	);
}
