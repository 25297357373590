import { Typography } from '@mui/material';
import noItemsFoundImage from '../../../shared/assets/images/no-items-found.png';

export function NoProductsFound() {
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				alignItems: 'center',
				height: '70vh',
			}}
		>
			<img src={noItemsFoundImage} alt="Gdoorzinho" width="150px" />
			<Typography variant="subtitle1" align="center">
				Nenhum produto encontrado
			</Typography>
		</div>
	);
}
