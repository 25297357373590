import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Box, IconButton, Typography } from '@mui/material';
import { theme } from '../theme';

type Props = {
	quantity: number;
	onIncrement: () => void;
	onDecrement: () => void;
	incrementEnabled?: boolean;
	decrementEnabled?: boolean;
};

export function QuantityManager({
	quantity,
	onIncrement,
	onDecrement,
	incrementEnabled = true,
	decrementEnabled = true,
}: Props) {
	return (
		<Box display="flex" alignItems="center" height="100%">
			<IconButton onClick={onDecrement}>
				<RemoveIcon
					style={{
						color: decrementEnabled
							? theme.palette.text.primary
							: theme.palette.text.disabled,
					}}
				/>
			</IconButton>
			<Box margin="0 8px">
				<Typography variant="h6">{quantity}</Typography>
			</Box>
			<IconButton onClick={onIncrement}>
				<AddIcon
					style={{
						color: incrementEnabled
							? theme.palette.text.primary
							: theme.palette.text.disabled,
					}}
				/>
			</IconButton>
		</Box>
	);
}
