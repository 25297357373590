import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Button, Card, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useBag } from '../../../../shared/contexts';

export const SelectedAddressCard = () => {
	const navigate = useNavigate();
	const deliveryAddress = useBag().state.deliveryAddress;

	return (
		<Card
			variant="outlined"
			sx={{
				padding: '16px',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			<Box>
				<Typography variant="h6">Endereço de entrega</Typography>
				{deliveryAddress ? (
					<Typography>
						{deliveryAddress.name}: {deliveryAddress.formatted}
					</Typography>
				) : (
					<Typography>Nenhum endereço selecionado</Typography>
				)}
			</Box>
			<Box display="flex" alignItems="center">
				<Button onClick={() => navigate('/bag/delivery-address/list')}>
					<Typography>{deliveryAddress ? 'Alterar' : 'Selecionar'}</Typography>
					<ArrowForwardIosIcon fontSize="small" sx={{ marginLeft: '12px' }} />
				</Button>
			</Box>
		</Card>
	);
};
