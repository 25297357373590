import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorDialog, EstablishmentAppBar } from '../../../shared/components';
import { Loading } from '../../../shared/components/Loading';
import { useBag } from '../../../shared/contexts';
import { DeliveryAddress } from '../../../shared/entities/delivery_address';
import { AppError } from '../../../shared/errors';
import {
	CustomerRepository,
	EstablishmentRepository,
} from '../../../shared/repositories';
import { BagFooter } from '../components';
import { getDeliveryAvailability } from '../delivery_availability';
import { deliveryErrors } from '../delivery_errors';
import { DeliveryFeeCard } from './components/delivery_fee_card';
import { MinOrderValueCard } from './components/min_order_value_card';
import { SelectedAddressCard } from './components/selected_address_card';
import { UnavailableDeliveryCard } from './components/unavailable_delivery_card';

type Props = {
	customerRepository: CustomerRepository;
	establishmentRepository: EstablishmentRepository;
};

type DeliveryStatus = 'ok' | 'minOrderValue' | 'unavailable';

export const DeliveryAddressPage = ({
	customerRepository,
	establishmentRepository,
}: Props) => {
	const navigate = useNavigate();
	const {
		state: bagState,
		setDeliveryAddress,
		unsetDeliveryAddress,
	} = useBag();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState<AppError | null>(null);
	const [status, setStatus] = useState<DeliveryStatus | null>(null);
	const [formError, setFormError] = useState<string>('');

	useEffect(() => {
		if (!bagState.deliveryAddress) {
			setIsLoading(true);
			customerRepository
				.getMainDeliveryAddress()
				.then((address) => (address ? loadDeliveryFee(address) : null))
				.catch(setError)
				.finally(() => setIsLoading(false));
		} else if (bagState.deliveryFee === undefined) {
			loadDeliveryFee(bagState.deliveryAddress);
		}
	}, [bagState.deliveryAddress]);

	const loadDeliveryFee = async (address: DeliveryAddress) => {
		setIsLoading(true);
		Promise.all([
			establishmentRepository.getDeliveryDistrics(),
			establishmentRepository.getDeliveryConfig(),
		])
			.then(([districts, config]) => {
				const result = getDeliveryAvailability({
					config,
					districts,
					address: {
						city: address.city,
						neighborhood: address.neighborhood,
						region: address.region,
					},
					bagTotal: bagState.total,
				});

				if (!result.error || result.error.type === 'minOrderValue') {
					setDeliveryAddress(
						address,
						result.deliveryFee!,
						result.minOrderValue!
					);
				}

				if (result.error) {
					switch (result.error!.type) {
						case 'minOrderValue':
							setStatus('minOrderValue');
							break;
						default:
							unsetDeliveryAddress();
							break;
					}
				}
			})
			.catch(setError)
			.finally(() => setIsLoading(false));
	};

	useEffect(() => {
		if (!bagState.deliveryAddress) {
			return setStatus(null);
		}
		if (bagState.deliveryFee === undefined || bagState.deliveryFee === null) {
			return setStatus('unavailable');
		} else if (bagState.total < bagState.minOrderValue!) {
			return setStatus('minOrderValue');
		}
		return setStatus('ok');
	}, [
		bagState.deliveryAddress,
		bagState.deliveryFee,
		bagState.minOrderValue,
		bagState.total,
	]);

	function nextStep() {
		if (!bagState.deliveryAddress) {
			return setFormError('Selecione um endereço de entrega');
		} else if (status === 'unavailable') {
			return setFormError('Entrega não disponível para o endereço selecionado');
		} else if (status === 'minOrderValue') {
			return setFormError(
				deliveryErrors.minOrderValue(bagState.minOrderValue!).message
			);
		}

		if (status === 'ok') {
			navigate('/bag/payment');
		}
	}

	return (
		<>
			<EstablishmentAppBar />
			<ErrorDialog
				open={!!formError}
				close={() => setFormError('')}
				error={formError}
			/>
			<Box padding="20px">
				<Typography marginBottom="20px" variant="h5">
					Selecione o endereço de entrega
				</Typography>
				{isLoading && <Loading />}
				{error && <Typography color="error">{error.message}</Typography>}
				{!isLoading && !error && <SelectedAddressCard />}
				{!isLoading && (status === 'ok' || status === 'minOrderValue') && (
					<DeliveryFeeCard fee={bagState.deliveryFee!} />
				)}
				{!isLoading && status === 'minOrderValue' && (
					<MinOrderValueCard minOrderValue={bagState.minOrderValue} />
				)}
				{!isLoading && status === 'unavailable' && <UnavailableDeliveryCard />}
			</Box>
			<BagFooter total={bagState.total} onNext={nextStep} />
		</>
	);
};
