import { SessionExpiredError } from '../../shared/errors';
import { CustomerStatus } from '../entities';
import { DeliveryAddress } from '../entities/delivery_address';
import { HttpClient } from '../http_client/http_client';
import { BaseRepository } from './base_repository';

export class CustomerRepository extends BaseRepository {
	constructor(private readonly httpClient: HttpClient) {
		super();
	}

	async getStatus(): Promise<CustomerStatus> {
		const session = this.getSession();
		if (!session || session.isExpired) throw new SessionExpiredError();
		const json = await this.httpClient.get(
			`/establishments/${session.establishment.uuid}/customer/status`
		);
		return json.status as CustomerStatus;
	}

	async getDeliveryAddresses(): Promise<DeliveryAddress[]> {
		const session = this.getSession();
		if (!session || session.isExpired) throw new SessionExpiredError();
		const json = await this.httpClient.get(
			`/establishments/${session.establishment.uuid}/customer/delivery-addresses`
		);
		return json.map(DeliveryAddress.fromJson);
	}

	async deleteDeliveryAddress(addressId: number): Promise<void> {
		const session = this.getSession();
		if (!session || session.isExpired) throw new SessionExpiredError();
		await this.httpClient.delete(
			`/establishments/${session.establishment.uuid}/customer/delivery-addresses/${addressId}`
		);
	}

	async updateDeliveryAddress(
		address: DeliveryAddress
	): Promise<DeliveryAddress> {
		const session = this.getSession();
		if (!session || session.isExpired) throw new SessionExpiredError();
		const json = await this.httpClient.put(
			`/establishments/${session.establishment.uuid}/customer/delivery-addresses/${address.id}`,
			{
				...address.toJson(),
				isPending: undefined,
			}
		);
		return DeliveryAddress.fromJson(json);
	}

	async getMainDeliveryAddress(): Promise<DeliveryAddress | null> {
		const addresses = await this.getDeliveryAddresses();
		return addresses.find((address) => address.isMain) ?? null;
	}
}
