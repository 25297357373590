import { Address } from './address';

export enum DeliveryAddressMaxLength {
	name = 40,
}

export class DeliveryAddress extends Address {
	constructor(
		public name: string,
		zipCode: string,
		street: string,
		number: string,
		complement: string,
		neighborhood: string,
		city: City,
		state: string,
		public isPending: boolean,
		public region?: string | null,
		public isMain?: boolean,
		public id?: number | string
	) {
		super(zipCode, street, neighborhood, city, state, number, complement);
	}

	static fromJson(json: any) {
		return new DeliveryAddress(
			json.name,
			json.zipCode,
			json.street,
			json.number,
			json.complement,
			json.neighborhood,
			{ code: json.cityCode, name: json.city },
			json.state,
			json.isPending ?? false,
			json.region,
			json.isMaster,
			json.id
		);
	}

	get formatted() {
		return (
			`
			${this.street}, 
			${this.number}, 
			` +
			(this.complement ? `${this.complement},` : '') +
			`
			${this.neighborhood},` +
			(this.region ? ` ${this.region},` : '') +
			`
			${this.city.name} - ${this.state}
		`
		);
	}

	toJson() {
		return {
			id: this.id,
			name: this.name,
			zipCode: this.zipCode,
			street: this.street,
			number: this.number,
			complement: this.complement,
			neighborhood: this.neighborhood,
			city: this.city.name,
			cityCode: this.city.code,
			state: this.state,
			region: this.region,
			isMaster: this.isMain,
			isPending: this.isPending,
		};
	}
}

export type City = {
	code: string;
	name: string;
};
