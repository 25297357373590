import { Typography } from '@mui/material';

export function Header() {
	return (
		<>
			<Typography variant="h6">Qual serviço você precisa do garçom?</Typography>
			<Typography variant="body1" marginTop="5px">
				Insira abaixo o motivo ou apenas clique em "Chamar garçom".
			</Typography>
		</>
	);
}
