import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';

export function DocumentInput({
	label,
	value,
	error,
	onChange,
	style,
}: {
	label?: string;
	value: string;
	error: string;
	onChange: (value: string) => void;
	style?: React.CSSProperties;
}) {
	return (
		<InputMask
			mask="999.999.999-99"
			value={value}
			onChange={(e) => onChange(e.target.value.onlyNumbers())}
		>
			{(inputProps: any) => (
				<TextField
					{...inputProps}
					style={style}
					fullWidth={true}
					label={label ?? 'Qual é o seu CPF?'}
					placeholder="Digite seu CPF"
					variant="standard"
					error={error?.trim().length > 0}
					helperText={error?.trim()}
					type="tel"
				/>
			)}
		</InputMask>
	);
}
