export class DeliveryConfig {
	constructor(
		public deliverOnUnregisteredRegion: boolean,
		public defaultDeliveryFee: number,
		public defaultMinimumOrderValue: number,
		public acceptOrderFromDifferentCity: boolean
	) {}

	static fromJson(json: any): DeliveryConfig {
		return new DeliveryConfig(
			json.deliverOnUnregisteredRegion,
			json.defaultDeliveryFee,
			json.minimumOrderFee,
			json.acceptOrderFromDifferentCity
		);
	}
}
