import { Establishment } from './establishment';

export class Session {
	constructor(params: { establishment: Establishment; expiration: Date }) {
		this.establishment = params.establishment;
		this.expiration = params.expiration;
	}

	establishment: Establishment;
	expiration: Date;

	get isExpired(): boolean {
		return this.expiration <= new Date();
	}
}
