import { DeliveryMethod } from './delivery_method';

export class Config {
	private constructor(
		public tipPercent: number,
		public deliveryMethods: DeliveryMethod[],
		public chargeDeliveryTax: boolean,
		public takeoutTime: string,
		public deliveryTime: string,
		public requestDocument: boolean
	) {}

	static fromJson(json: any): Config {
		const takeoutTime = json.takeoutTime?.substring(0, 5);
		const deliveryTime = json.deliveryTime?.substring(0, 5);
		return new Config(
			json.tipPercent,
			json.deliveryMethods,
			json.chargeDeliveryTax,
			takeoutTime === '00:00' ? null : takeoutTime,
			deliveryTime === '00:00' ? null : deliveryTime,
			json.requestDocument
		);
	}

	getTimeOfMethod(method: DeliveryMethod): string | null {
		switch (method) {
			case DeliveryMethod.takeout:
				return this.takeoutTime;
			case DeliveryMethod.delivery:
				return this.deliveryTime;
			default:
				return null;
		}
	}
}
