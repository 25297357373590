import { Box, Typography } from '@mui/material';
import { useEstablishment } from '../../../shared/contexts/EstablishmentContext';

type Props = {
	customer?: { name: string };
};

export function OrderCreatedInfo({ customer }: Props) {
	const { establishment } = useEstablishment().state;

	return (
		<Box margin="20px 0">
			<Typography variant="subtitle1" fontWeight="bold" textAlign="left">
				Informações do pedido
			</Typography>
			<Box
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				margin="10px 0"
			>
				<Typography variant="subtitle2">Horário</Typography>
				<Typography variant="subtitle2" fontWeight="bold">
					{new Date().formatHourMinute()}
				</Typography>
			</Box>
			{establishment!.isSelfService && (
				<>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						margin="10px 0"
					>
						<Typography variant="subtitle2">Mesa</Typography>
						<Typography variant="subtitle2" fontWeight="bold">
							{establishment.table}
						</Typography>
					</Box>
					<Box
						display="flex"
						justifyContent="space-between"
						alignItems="center"
						margin="10px 0"
					>
						<Typography variant="subtitle2">Cliente</Typography>
						<Typography variant="subtitle2" fontWeight="bold">
							{customer!.name}
						</Typography>
					</Box>
				</>
			)}
		</Box>
	);
}
