import { Button } from '@mui/material';

type Props = {
	onClick: () => void;
};

export const LoadMoreButton = ({ onClick }: Props) => {
	return (
		<Button
			onClick={onClick}
			variant="text"
			style={{ alignSelf: 'center', marginTop: '10px' }}
		>
			Carregar mais
		</Button>
	);
};
