import { Box } from '@mui/material';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../shared/components';
import { PhoneInput } from '../../shared/components/PhoneInput';
import { UserAppBar } from '../../shared/components/UserAppBar';
import { useUser } from '../../shared/contexts';
import { AuthType } from '../../shared/entities/auth_type';
import { AppError } from '../../shared/errors';
import { AuthRepository } from '../../shared/repositories';
import { Validator } from '../../shared/validator';

type Props = {
	authRepository: AuthRepository;
};

export function AuthPage({ authRepository }: Props) {
	const navigate = useNavigate();
	const { state: locationState } = useLocation() as any;
	const { setUser } = useUser();
	const [step, setStep] = useState(0);
	const [phone, setPhone] = useState({ value: '', error: '' });
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [verificationCode, setVerificationCode] = useState({
		value: '',
		error: '',
	});
	const phoneValidator = new Validator().required().phone();
	const verificationCodeValidator = new Validator()
		.required()
		.verificationCode();

	const onPhoneChanged = (value: string) => {
		setPhone({ value, error: phoneValidator.execute(value) });
	};

	const onVerificationCodeChanged = (value: string) => {
		setVerificationCode({
			value,
			error: verificationCodeValidator.execute(value),
		});
	};

	const sendVerificationCode = async (type: AuthType) => {
		setPhone({ ...phone, error: phoneValidator.execute(phone.value) });
		if (phone.error.length > 0) return;
		try {
			setIsSubmitting(true);
			await authRepository.sendVerificationCode(phone.value, type);
			setStep(1);
		} catch (error) {
			if (error instanceof AppError) {
				setPhone({ ...phone, error: error.message });
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	const verifyCode = async () => {
		setVerificationCode({
			...verificationCode,
			error: verificationCodeValidator.execute(verificationCode.value),
		});
		if (verificationCode.error.length > 0) return;
		try {
			setIsSubmitting(true);
			const user = await authRepository.verifyCode(
				phone.value,
				verificationCode.value
			);
			setUser(user);
			if (user.completed) {
				return navigate(locationState?.from || '/');
			}
			return navigate('/profile', {
				state: { from: locationState?.from },
			});
		} catch (error) {
			if (error instanceof AppError) {
				setVerificationCode({ ...verificationCode, error: error.message });
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	const signUp = async () => {
		const phoneError = phoneValidator.execute(phone.value);
		setPhone({ ...phone, error: phoneError });
		if (phoneError.length > 0) return;
		try {
			setIsSubmitting(true);
			const user = await authRepository.signUp(phone.value);
			setUser(user);
			if (user.completed) {
				return navigate(locationState?.from || '/');
			}
			return navigate('/profile', {
				state: { from: locationState?.from },
			});
		} catch (error) {
			if (error instanceof AppError) {
				setPhone({ ...phone, error: error.message });
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	return (
		<>
			<UserAppBar title="Identificação" />
			<Box padding="8px">
				<PhoneInput
					value={phone.value}
					error={phone.error}
					onChange={onPhoneChanged}
				/>
				<Box
					sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
					height="60px"
					padding="50px 20px"
				>
					<PrimaryButton
						label="Avançar"
						onClick={signUp}
						isLoading={isSubmitting}
					/>
				</Box>

				{/* {step === 0 && (
					<PhoneStep
						phone={phone}
						setPhone={onPhoneChanged}
						onContinue={() => sendVerificationCode(AuthType.SMS)}
						isSubmitting={isSubmitting}
					/>
				)}
				{step === 1 && (
					<VerificationCodeStep
						code={verificationCode}
						setCode={onVerificationCodeChanged}
						phone={phone.value}
						onContinue={verifyCode}
						resendCode={() => sendVerificationCode(AuthType.WHATSAPP)}
						isSubmitting={isSubmitting}
					/>
				)} */}
			</Box>
		</>
	);
}
