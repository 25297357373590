import { Box, Button, Typography } from '@mui/material';

type Props = {
	total: number;
	onNext: () => void;
};

export const BagFooter = ({ total, onNext }: Props) => {
	return (
		<Box
			sx={{
				position: 'fixed',
				bottom: 0,
				left: 0,
				right: 0,
			}}
			height="60px"
			bgcolor="grey.100"
			display="flex"
			alignItems="center"
			justifyContent="space-between"
			padding="0 20px"
		>
			<Typography variant="h6">{total.toCurrency()}</Typography>
			<Button variant="contained" disableElevation onClick={onNext}>
				Continuar
			</Button>
		</Box>
	);
};
