import { LogoutOutlined } from '@mui/icons-material';
import { Box, Button, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../shared/components';
import { DocumentInput } from '../../shared/components/DocumentInput';
import { NameInput } from '../../shared/components/NameInput';
import { UserAppBar } from '../../shared/components/UserAppBar';
import { useUser } from '../../shared/contexts';
import { AppError } from '../../shared/errors';
import { UserRepository } from '../../shared/repositories';
import { Validator } from '../../shared/validator';

type Props = {
	userRepository: UserRepository;
};

export function ProfilePage({ userRepository }: Props) {
	const navigate = useNavigate();
	const { state: locationState } = useLocation() as any;
	const { state: userState, setUser, logout } = useUser();
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [name, setName] = useState({ value: '', error: '' });
	const [document, setDocument] = useState({ value: '', error: '' });
	const nameValidator = new Validator().required().minLength(3);
	const documentValidator = new Validator().cpf();
	const isFromBagFlow = locationState?.from?.includes('/bag');

	useEffect(() => {
		const user = userState.user;
		if (user?.name) {
			setName({ value: user.name, error: '' });
		}
		if (user?.document) {
			setDocument({ value: user.document, error: '' });
		}
	}, []);

	const onNameChanged = (value: string) => {
		setName({ value, error: nameValidator.execute(value) });
	};

	const onDocumentChanged = (value: string) => {
		setDocument({ value, error: documentValidator.execute(value) });
	};

	const save = async () => {
		const nameError = nameValidator.execute(name.value);
		const documentError = documentValidator.execute(document.value);
		setName({ ...name, error: nameError });
		setDocument({ ...document, error: documentError });
		if (nameError.length > 0 || documentError.length > 0) return;
		try {
			setIsSubmitting(true);
			userState.user!.name = name.value;
			userState.user!.document = document.value.onlyNumbers();
			const user = await userRepository.updateProfile(userState.user!);
			setUser(user);
			navigate(locationState?.from || '/');
		} catch (error) {
			if (error instanceof AppError) {
				setName({ ...name, error: error.message });
			}
		} finally {
			setIsSubmitting(false);
		}
	};

	const doLogout = () => {
		logout();
		navigate(-1);
	};

	return (
		<>
			<UserAppBar
				title="Meu perfil"
				actions={
					!isFromBagFlow && (
						<Button onClick={doLogout}>
							<Typography variant="subtitle1" marginRight="8px" color="error">
								Desconectar
							</Typography>
							<LogoutOutlined color="error" />
						</Button>
					)
				}
			/>
			<Box padding="8px 16px">
				<NameInput
					value={name.value}
					error={name.error}
					onChange={onNameChanged}
					style={{ marginTop: '10px' }}
				/>
				<DocumentInput
					value={document.value}
					error={document.error}
					onChange={onDocumentChanged}
					style={{ marginTop: '16px' }}
				/>
				<TextField
					fullWidth={true}
					label="Número de telefone"
					variant="standard"
					style={{ marginTop: '16px' }}
					value={userState.user?.phone.toCellPhone()}
					disabled={true}
				/>
				<Box
					sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
					height="60px"
					padding="50px 20px"
				>
					<PrimaryButton
						label="Concluir"
						onClick={save}
						isLoading={isSubmitting}
					/>
				</Box>
			</Box>
		</>
	);
}
