import AddIcon from '@mui/icons-material/Add';
import { Box, Fab, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorDialog } from '../../../../shared/components';
import { Loading } from '../../../../shared/components/Loading';
import { UserAppBar } from '../../../../shared/components/UserAppBar';
import { useBag, useEstablishment } from '../../../../shared/contexts';
import { DeliveryAddress } from '../../../../shared/entities/delivery_address';
import { Region } from '../../../../shared/entities/district';
import { AppError, UnexpectedError } from '../../../../shared/errors';
import {
	CustomerRepository,
	EstablishmentRepository,
} from '../../../../shared/repositories';
import { getDeliveryAvailability } from '../../delivery_availability';
import { deliveryErrors } from '../../delivery_errors';
import { DeliveryAddressTile } from './components/DeliveryAddressTile';
import { DistrictRegionSelect } from './components/DistrictRegionSelect';

type Props = {
	customerRepository: CustomerRepository;
	establishmentRepository: EstablishmentRepository;
};

export function DeliveryAddressesPage({
	customerRepository,
	establishmentRepository,
}: Props) {
	const navigate = useNavigate();
	const { establishment, config } = useEstablishment().state;
	const {
		state: bagState,
		setDeliveryAddress,
		removeDeliveryAddress,
	} = useBag();
	const [isLoading, setIsLoading] = useState(true);
	const [addresses, setAddresses] = useState<DeliveryAddress[]>([]);
	const [error, setError] = useState<AppError | null>(null);
	const [formError, setFormError] = useState<string | null>(null);
	const [openRegionSelect, setOpenRegionSelect] = useState(false);
	const [regions, setRegions] = useState<Region[]>([]);
	const [selectedDeliveryAddress, setSelectedDeliveryAddress] =
		useState<DeliveryAddress | null>();

	const closeRegionSelect = () => setOpenRegionSelect(false);

	useEffect(() => {
		loadAddresses();
	}, []);

	const loadAddresses = () => {
		setIsLoading(true);
		customerRepository
			.getDeliveryAddresses()
			.then((addresses) =>
				setAddresses([...bagState.pendingDeliveryAddresses, ...addresses])
			)
			.catch((_) => setError(new UnexpectedError()))
			.finally(() => setIsLoading(false));
	};

	const handleSetMain = async (address: DeliveryAddress) => {
		setIsLoading(true);
		try {
			const currentMain = addresses.find((a) => a.isMain);
			if (currentMain) {
				currentMain.isMain = false;
				await customerRepository.updateDeliveryAddress(currentMain);
			}
			address!.isMain = true;
			await customerRepository.updateDeliveryAddress(address!);
			loadAddresses();
		} catch (error) {
			setIsLoading(false);
			console.error('Error setting main address', error);
		}
	};

	const handleDelete = (address: DeliveryAddress) => {
		if (address.isPending) {
			removeDeliveryAddress(bagState.pendingDeliveryAddresses.indexOf(address));
			setAddresses(
				addresses.filter((_, i) => i !== addresses.indexOf(address))
			);
			return;
		}
		setIsLoading(true);
		customerRepository
			.deleteDeliveryAddress(address.id! as number)
			.then(loadAddresses)
			.catch((error) => {
				setIsLoading(false);
				console.error('Error deleting address', error);
			});
	};

	const handleEdit = (address: DeliveryAddress) => {
		navigate(`/bag/delivery-address/${address.id}/edit`);
	};

	const onAddressSelected = (address: DeliveryAddress) => {
		if (!config.chargeDeliveryTax) {
			setDeliveryAddress(address, 0, 0);
			navigate(-1);
			return;
		}
		Promise.all([
			establishmentRepository.getDeliveryDistrics(),
			establishmentRepository.getDeliveryConfig(),
		]).then(([districts, config]) => {
			const result = getDeliveryAvailability({
				config,
				districts,
				address: {
					city: address.city,
					neighborhood: address.neighborhood,
					region: address.region,
				},
				bagTotal: bagState.total,
			});

			if (!result.error) {
				setDeliveryAddress(address, result.deliveryFee!, result.minOrderValue!);
				return navigate(-1);
			}

			switch (result.error.type) {
				case 'requireRegion':
					setSelectedDeliveryAddress(address);
					setRegions(result.regions!);
					setOpenRegionSelect(true);
					break;
				default:
					setFormError(result.error.message);
					break;
			}
		});
	};

	const onSelectRegion = (region: Region) => {
		if (!region.hasDelivery) {
			setFormError(deliveryErrors.unavailableDeliveryOnRegion.message);
			return;
		}
		if (bagState.total < region.minOrderValue) {
			setFormError(deliveryErrors.minOrderValue(region.minOrderValue).message);
			return;
		}

		selectedDeliveryAddress!.region = region.name;
		customerRepository
			.updateDeliveryAddress(selectedDeliveryAddress!)
			.then((updatedAdddress) => {
				const { deliveryFee, minOrderValue } = region;
				setDeliveryAddress(updatedAdddress, deliveryFee, minOrderValue);
				navigate(-1);
			});
	};

	return (
		<>
			<DistrictRegionSelect
				regions={regions}
				onSelect={onSelectRegion}
				open={openRegionSelect}
				close={closeRegionSelect}
			/>
			<ErrorDialog
				error={formError}
				open={!!formError}
				close={() => setFormError(null)}
			/>
			<UserAppBar title={`Meus endereços em ${establishment.name}`} />
			<Box padding="8px" marginTop="16px">
				{isLoading && <Loading />}
				{error && <Typography color="error">{error.message}</Typography>}
				{!isLoading && addresses.length === 0 && (
					<Typography variant="h6" color="textSecondary" textAlign="center">
						Você ainda não tem nenhum endereço em {establishment.name}
					</Typography>
				)}
				{!isLoading &&
					addresses.map((address) => (
						<DeliveryAddressTile
							address={address}
							onSelected={() => onAddressSelected(address)}
							onSelectAsMain={() => handleSetMain(address)}
							onDelete={() => handleDelete(address)}
							onEdit={() => handleEdit(address)}
						/>
					))}
			</Box>

			<Box position="fixed" bottom="16px" right="16px">
				<Fab
					color="primary"
					onClick={() => navigate('/bag/delivery-address/create')}
				>
					<AddIcon />
				</Fab>
			</Box>
		</>
	);
}
