import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { Divider, IconButton } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEstablishment } from '../contexts/EstablishmentContext';
import { theme } from '../theme';

export function EstablishmentAppBar() {
	const navigate = useNavigate();
	const location = useLocation();
	const { establishment } = useEstablishment().state;

	function goBack() {
		navigate(-1);
	}

	return (
		<>
			<AppBar
				sx={{
					top: 0,
					height: '65px',
					display: 'flex',
					justifyContent: 'center',
				}}
			>
				<Toolbar>
					{location.pathname !== '/' && (
						<IconButton
							style={{
								color: theme.palette.text.primary,
								marginRight: '10px',
							}}
							onClick={goBack}
						>
							<KeyboardBackspaceOutlinedIcon />
						</IconButton>
					)}
					<Typography
						variant="h6"
						component="div"
						sx={{ flexGrow: 1 }}
						fontWeight="bold"
					>
						{establishment.name}
						{establishment.isSelfService && (
							<Typography variant="subtitle2" component="div">
								Mesa {establishment.table}
							</Typography>
						)}
					</Typography>
					{establishment.imageUrl && (
						<img
							height="80%"
							style={{ objectFit: 'contain', borderRadius: '4px' }}
							src={establishment.imageUrl}
							srcSet={establishment.imageUrl}
							alt={establishment.name}
							loading="lazy"
						/>
					)}
				</Toolbar>
				<Divider />
			</AppBar>
		</>
	);
}
