import {
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	List,
	ListItem,
	ListItemButton,
} from '@mui/material';
import { Region } from '../../../../../shared/entities/district';

export function DistrictRegionSelect({
	regions,
	onSelect,
	open,
	close,
}: {
	regions: Region[];
	onSelect: (address: Region) => void;
	open: boolean;
	close: () => void;
}) {
	return (
		<Dialog open={open} onClose={close} maxWidth="sm" fullWidth>
			<DialogTitle style={{ paddingBottom: '8px' }}>
				Selecione a referência
			</DialogTitle>
			<List>
				{regions.map((region) => (
					<ListItem
						key={region.name}
						onClick={() => onSelect(region)}
						style={{ margin: '0', padding: '0' }}
					>
						<ListItemButton
							sx={{
								padding: '25px',
								margin: '0',
							}}
						>
							{region.name}
						</ListItemButton>
					</ListItem>
				))}
			</List>
			<DialogActions>
				<Button onClick={() => close()}>Voltar</Button>
			</DialogActions>
		</Dialog>
	);
}
