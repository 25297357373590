declare global {
	export interface Map<K, V> {
		getOr(key: K, defaultValue: V): V;
	}
}

// eslint-disable-next-line
Map.prototype.getOr = function (
	this: Map<any, any>,
	key: any,
	defaultValue: any
) {
	const value = this.get(key);
	return value === undefined ? defaultValue : value;
};

export {};
