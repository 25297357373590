import { TextField } from '@mui/material';
import InputMask from 'react-input-mask';

export function PhoneInput({
	value,
	error,
	onChange,
	style,
}: {
	value: string;
	error: string;
	onChange: (value: string) => void;
	style?: any;
}) {
	return (
		<InputMask
			mask="(99) 99999-9999"
			value={value}
			onChange={(e) => onChange(e.target.value.onlyNumbers())}
		>
			{(inputProps: any) => (
				<TextField
					{...inputProps}
					style={style}
					fullWidth={true}
					label="Qual seu número de telefone?"
					placeholder="Digite seu número de telefone"
					variant="standard"
					error={error?.trim().length > 0}
					helperText={error?.trim()}
					type="tel"
				/>
			)}
		</InputMask>
	);
}
