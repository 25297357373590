import { Box, Typography } from '@mui/material';
import * as entities from '../../../shared/entities';

export function PaymentMethod({ value }: { value: entities.PaymentMethod }) {
	return (
		<Box>
			<Typography
				margin="20px 0 5px 0"
				variant="h6"
				fontWeight="bold"
				textAlign="left"
			>
				Método de pagamento
			</Typography>
			<Box padding="20px" bgcolor="grey.100" borderRadius="10px">
				<Typography variant="body1" textAlign="left">
					{entities.paymentMethodLabels[value]}
				</Typography>
			</Box>
		</Box>
	);
}
