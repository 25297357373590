import { Box, Typography } from '@mui/material';
import { useEstablishment } from '../../../shared/contexts';
import { DeliveryMethod, deliveryMethodLabels } from '../../../shared/entities';
import { DeliveryAddress } from '../../../shared/entities/delivery_address';

export function DeliveryType({
	value,
	address,
}: {
	value: DeliveryMethod;
	address?: DeliveryAddress;
}) {
	const config = useEstablishment().state.config;
	const time = config.getTimeOfMethod(value);
	return (
		<Box>
			<Typography
				margin="20px 0 5px 0"
				variant="h6"
				fontWeight="bold"
				textAlign="left"
			>
				Entrega
			</Typography>
			<Box padding="20px" bgcolor="grey.100" borderRadius="10px">
				<Typography variant="body1" textAlign="left">
					{value === DeliveryMethod.takeout
						? deliveryMethodLabels[value]
						: address?.formatted}
				</Typography>
				{time ? (
					<Typography variant="body1" marginTop="12px">
						Tempo estimado: {time} min
					</Typography>
				) : null}
			</Box>
		</Box>
	);
}
