import { useEffect, useRef } from 'react';

/// This hook is used to block the first call of the useEffect hook and forward each consecutive one
export const useDidUpdate = (
	callback: React.EffectCallback,
	dependencies?: React.DependencyList | undefined
) => {
	const didMountRef = useRef(false);

	useEffect(() => {
		if (didMountRef.current) {
			callback();
		} else {
			didMountRef.current = true;
		}
	}, dependencies);
};
