import { User } from '../entities';
import { HttpClient } from '../http_client/http_client';

export class UserRepository {
	constructor(private readonly httpClient: HttpClient) {}

	async getUser(): Promise<User> {
		const json = await this.httpClient.get('/user/profile');
		return User.fromJson(json);
	}

	async updateProfile(user: User): Promise<User> {
		const json = await this.httpClient.patch('/user/profile', {
			name: user.name,
			document: user.document,
		});
		return User.fromJson(json);
	}

	async saveFcmToken(fcmToken: string): Promise<void> {
		await this.httpClient.post('/user/fcm-token', { fcmToken });
	}
}
