import { Address } from '../entities/address';
import { UnexpectedError, ZipCodeNotFoundError } from '../errors';

export class AddressRepository {
	async getAddressByZipCode(zipCode: string): Promise<Address> {
		try {
			const response = await fetch(`https://viacep.com.br/ws/${zipCode}/json`);
			const json = await response.json();
			if (json.erro) {
				throw new ZipCodeNotFoundError();
			}
			return Address.fromViaCEP(json);
		} catch (error) {
			if (error instanceof ZipCodeNotFoundError) {
				throw error;
			}
			throw new UnexpectedError();
		}
	}
}
