import { ListItem, ListItemButton, Radio, Typography } from '@mui/material';
import { QuantityManager } from '../../../shared/components';
import { Additional, AdditionalGroup } from '../../../shared/entities';
import { useProduct } from '../ProductContext';

type Props = {
	additional: Additional;
	group: AdditionalGroup;
};

export function AdditionalTile({ additional, group }: Props) {
	const { selectAdditional } = useProduct();

	return (
		<ListItem
			key={additional.id}
			style={{
				margin: '15px 0',
			}}
			onClick={() => {
				if (!group.isSingleChoice) return;
				selectAdditional(additional);
			}}
			secondaryAction={
				group.isSingleChoice ? (
					<SingleChoiceTrailing additional={additional} />
				) : (
					<MultipleChoiceTrailing additional={additional} />
				)
			}
			disablePadding
		>
			<ListItemButton
				sx={{
					padding: additional.price > 0 ? undefined : '8px',
					'&:hover': { backgroundColor: 'transparent' },
				}}
				disableTouchRipple
			>
				<div>
					<Typography variant="subtitle2" paddingRight={12}>
						{additional.name}
					</Typography>
					{additional.price > 0 && (
						<Typography variant="subtitle2" fontWeight="bold">
							+ {additional.price.toCurrency()}
						</Typography>
					)}
					{additional.description && (
						<Typography
							variant="body2"
							color="text.secondary"
							paddingRight={12}
						>
							{additional.description}
						</Typography>
					)}
				</div>
			</ListItemButton>
		</ListItem>
	);
}

function MultipleChoiceTrailing({ additional }: { additional: Additional }) {
	const {
		incrementAdditionalQuantity,
		decrementAdditionalQuantity,
		limitAdditionalReached,
	} = useProduct();
	const quantity = useProduct().state.additionals.getOr(additional, 0);

	return (
		<QuantityManager
			quantity={quantity}
			onIncrement={() => incrementAdditionalQuantity(additional)}
			onDecrement={() => decrementAdditionalQuantity(additional)}
			incrementEnabled={!limitAdditionalReached(additional)}
			decrementEnabled={quantity > 0}
		/>
	);
}

function SingleChoiceTrailing({ additional }: { additional: Additional }) {
	const checked = useProduct().state.additionals.get(additional) === 1;
	return <Radio value={additional} checked={checked} />;
}
