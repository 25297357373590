import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EstablishmentAppBar } from '../../shared/components';
import { useBag, useUser } from '../../shared/contexts';
import { BagFooter, Products } from './components';
import { AddMoreItemsButton } from './components/AddMoreItemsButton';
import { EmptyBag } from './components/EmptyBag';

export function BagPage() {
	const { state: userState } = useUser();
	const { state: bagState } = useBag();
	const navigate = useNavigate();

	const nextStep = () => {
		const destPath = '/bag/delivery-method';
		if (userState.isLogged) {
			if (userState.user!.completed) return navigate(destPath);
			return navigate('/profile', { state: { from: destPath } });
		}
		navigate('/auth', { state: { from: destPath } });
	};

	return (
		<>
			<EstablishmentAppBar />
			<Box padding="20px">
				{bagState.isEmpty ? (
					<EmptyBag />
				) : (
					<>
						<Typography variant="h5">Sacola</Typography>
						<div style={{ marginTop: '10px', textAlign: 'center' }}>
							<Products canRemove={true} />
							<AddMoreItemsButton />
						</div>

						<BagFooter total={bagState.subtotal} onNext={nextStep} />
					</>
				)}
			</Box>
		</>
	);
}
