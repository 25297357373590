import { HttpClient } from '../shared/http_client';
import {
	AuthRepository,
	BagRepository,
	CustomerRepository,
	EstablishmentRepository,
	OrderRepository,
	ProductRepository,
	SessionRepository,
	UserRepository,
	WaiterRepository,
} from '../shared/repositories';
import { AddressRepository } from './repositories/address_repository';

const httpClient = new HttpClient({
	baseUrl: process.env.REACT_APP_API_URL as string,
});

const orderRepository = new OrderRepository(httpClient);
const waiterRepository = new WaiterRepository(httpClient);
const userRepository = new UserRepository(httpClient);
const sessionRepository = new SessionRepository();
const productRepository = new ProductRepository(httpClient);
const establishmentRepository = new EstablishmentRepository(httpClient);
const authRepository = new AuthRepository(httpClient);
const customerRepository = new CustomerRepository(httpClient);
const bagRepository = new BagRepository();
const addressRepository = new AddressRepository();

export {
	addressRepository,
	authRepository,
	bagRepository,
	customerRepository,
	establishmentRepository,
	orderRepository,
	productRepository,
	sessionRepository,
	userRepository,
	waiterRepository,
};
