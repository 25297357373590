import ArrowForward from '@mui/icons-material/ArrowForward';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useBag, useUser } from '../../../shared/contexts';

export function Customer() {
	const navigate = useNavigate();
	const user = useUser().state.user;
	const bagState = useBag().state;

	const handleEditProfile = () => {
		navigate('/profile', { state: { from: '/bag/confirm' } });
	};

	return (
		<>
			<Typography
				margin="20px 0 5px 0"
				variant="h6"
				fontWeight="bold"
				textAlign="left"
			>
				Cliente
			</Typography>
			<Box
				padding="20px"
				bgcolor="grey.100"
				borderRadius="10px"
				display="flex"
				justifyContent="space-between"
				alignItems="center"
			>
				<Box>
					<Typography variant="body1" textAlign="left">
						{user?.name}
					</Typography>
					{bagState.customerDocument && (
						<Typography variant="body1" textAlign="left">
							{bagState.customerDocument.toDocument()}
						</Typography>
					)}
				</Box>
				<Button
					color="primary"
					onClick={handleEditProfile}
					endIcon={<ArrowForward />}
				>
					Editar perfil
				</Button>
			</Box>
		</>
	);
}
