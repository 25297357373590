export enum PaymentMethod {
	cash = 'cash',
	card = 'card',
	pix = 'pix',
}

export const paymentMethodLabels = {
	[PaymentMethod.cash]: 'Dinheiro',
	[PaymentMethod.card]: 'Cartão',
	[PaymentMethod.pix]: 'Pix',
};
