import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { User } from '../entities';
import { AuthRepository, UserRepository } from '../repositories';

type UserType = {
	state: {
		user: User | null;
		isLogged: boolean;
	};
	setUser: (user: User) => void;
	logout: () => void;
};

const DEFAULT_VALUE = {
	state: {
		user: null as User | null,
		isLogged: false,
	},
	setUser: () => {},
	logout: () => {},
};

const UserContext = React.createContext<UserType>(DEFAULT_VALUE);

export const useUser = (): UserType => useContext(UserContext);

type Props = {
	children: ReactNode;
	authRepository: AuthRepository;
	userRepository: UserRepository;
};

export const UserContextProvider = ({
	children,
	authRepository,
	userRepository,
}: Props) => {
	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(false);
	const [state, setState] = useState(DEFAULT_VALUE.state);

	useEffect(() => {
		const isLogged = authRepository.isLogged();
		if (!isLogged) return;
		setIsLoading(true);
		userRepository
			.getUser()
			.then((user) => {
				setUser(user);
				if (!user.completed) {
					navigate('/profile');
				}
			})
			.catch((error) => console.error(error))
			.finally(() => setIsLoading(false));
	}, []);

	const setUser = (user: User) => {
		setState({ ...state, user, isLogged: true });
	};

	const logout = () => {
		authRepository.logout();
		setState({ ...state, user: null, isLogged: false });
	};

	if (isLoading) return <></>;
	return (
		<UserContext.Provider value={{ state, setUser, logout }}>
			{children}
		</UserContext.Provider>
	);
};
