import { Box, CircularProgress, Typography } from '@mui/material';

export function PrimaryButton({
	label,
	isLoading,
	onClick,
}: {
	label: string;
	isLoading?: boolean;
	onClick: () => void;
}) {
	return (
		<Box
			display="flex"
			flexDirection="row"
			justifyContent="center"
			alignItems="center"
			height="50px"
			marginTop="24px"
			bgcolor="primary.main"
			onClick={isLoading ? undefined : onClick}
			style={{ cursor: 'pointer' }}
		>
			{isLoading ? (
				<CircularProgress disableShrink style={{ color: 'white' }} />
			) : (
				<Typography variant="subtitle1" color="white">
					{label}
				</Typography>
			)}
		</Box>
	);
}
