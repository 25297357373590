import { Buffer } from 'buffer';
import { Establishment, Session } from '../entities';

export abstract class BaseRepository {
	getSession(): Session | null {
		const token = sessionStorage.getItem('token');
		const expiration = sessionStorage.getItem('sessionExpiration');
		if (!token || !expiration) return null;
		const establishmentJson = JSON.parse(
			Buffer.from(token, 'base64').toString()
		);
		return new Session({
			establishment: Establishment.fromJson(establishmentJson),
			expiration: new Date(expiration),
		});
	}
}
