export class DeliveryDistrict {
	constructor(
		public state: string,
		public city: { name: string; code: string },
		public districts: District[]
	) {}

	static fromJson(json: any): DeliveryDistrict {
		return new DeliveryDistrict(
			json.state,
			{ name: json.city, code: json.cityCode },
			json.districts?.map(District.fromJson) ?? []
		);
	}
}

export class District {
	constructor(
		public neighborhood: string,
		public region?: string,
		public regions?: Region[],
		public hasDelivery?: boolean,
		public minOrderValue?: number,
		public deliveryFee?: number,
		public isUnregistered?: boolean
	) {}

	static fromJson(json: any): District {
		return new District(
			json.neighborhood,
			json.region,
			json.regions?.map(Region.fromJson) ?? [],
			json.hasDelivery,
			json.minOrderData,
			json.deliveryFee,
			false
		);
	}

	static unregistered(): District {
		return new District(
			'Não encontrei meu bairro',
			undefined,
			[],
			false,
			0,
			0,
			true
		);
	}

	get isRegistered(): boolean {
		return !this.isUnregistered;
	}
}

export class Region {
	constructor(
		public name: string,
		public hasDelivery: boolean,
		public minOrderValue: number,
		public deliveryFee: number
	) {}

	static fromJson(json: any): Region {
		return new Region(
			json.region,
			json.hasDelivery,
			json.minOrderData,
			json.deliveryFee
		);
	}
}
