import { CircularProgress, List } from '@mui/material';
import { Box } from '@mui/system';
import { Product } from '../../../shared/entities';
import { LoadMoreButton } from './LoadMoreButton';
import { NoProductsFound } from './NoProductsFound';
import { ProductTile } from './ProductTile';

type Props = {
	products: Product[];
	hasMore: boolean;
	isLoading: boolean;
	loadMore: () => void;
};

export function Products({ products, hasMore, isLoading, loadMore }: Props) {
	if (!products) return <NoProductsFound />;
	//	<Box sx={{ width: '100%' }} style={{ margin: '0 5px' }}>
	return (
<List style={{ padding: '10px 20px' }}>
			{products.map((product, index) => {
				const isLast = index === products.length - 1;
				return (
					<ProductTile key={product.id} product={product} isLast={isLast} />
				);
			})}
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				{isLoading && (
					<CircularProgress
						disableShrink
						size={25}
						style={{ marginTop: '16px' }}
					/>
				)}
				{hasMore && !isLoading && <LoadMoreButton onClick={loadMore} />}
			</Box>
		</List>
	);
}
