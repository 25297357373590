import {
	Box,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EstablishmentAppBar } from '../../../shared/components';
import { useBag, useEstablishment } from '../../../shared/contexts';
import { DeliveryMethod, deliveryMethodLabels } from '../../../shared/entities';
import { BagFooter } from '../components';
export class Input {
	value = '';
	error = '';
}

export function DeliveryMethodPage() {
	const navigate = useNavigate();
	const { state, setDeliveryMethod } = useBag();
	const deliveryMethods = useEstablishment().state.config.deliveryMethods;

	const nextStep = () => {
		if (state.deliveryMethod) {
			setDeliveryMethod(state.deliveryMethod);
			if (state.deliveryMethod === DeliveryMethod.delivery) {
				return navigate('/bag/delivery-address');
			}
			return navigate('/bag/payment');
		}
	};

	return (
		<>
			<EstablishmentAppBar />
			<Box padding="20px">
				<Typography variant="h5">Selecione a forma de entrega</Typography>
				<Box
					padding="20px"
					bgcolor="grey.100"
					marginTop="25px"
					borderRadius="8px"
				>
					<FormControl fullWidth={true}>
						<RadioGroup
							name="delivery-method-group"
							value={state.deliveryMethod}
							onChange={(e) =>
								setDeliveryMethod(e.target.value as DeliveryMethod)
							}
						>
							{deliveryMethods.map((method) => (
								<FormControlLabel
									key={method}
									value={method}
									control={<Radio />}
									label={<MethodLabel method={method} />}
									style={{ margin: '8px 0' }}
								/>
							))}
						</RadioGroup>
					</FormControl>
				</Box>
				<BagFooter total={state.subtotal} onNext={nextStep} />
			</Box>
		</>
	);
}

function MethodLabel({ method }: { method: DeliveryMethod }) {
	const config = useEstablishment().state.config;
	const time = config.getTimeOfMethod(method);
	return (
		<>
			<Typography variant="subtitle2" lineHeight={0.8}>
				{deliveryMethodLabels[method]}
			</Typography>
			{time ? (
				<Typography variant="caption">Tempo estimado: {time} min</Typography>
			) : null}
		</>
	);
}
