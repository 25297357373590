import RefreshIcon from '@mui/icons-material/Refresh';
import { IconButton, Typography } from '@mui/material';

export function ReloadScreen({
	message,
	onReload,
}: {
	message: string;
	onReload?: () => void;
}) {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column',
				alignItems: 'center',
				height: '100vh',
			}}
		>
			<Typography variant="subtitle1" align="center">
				{message}
			</Typography>
			{onReload && (
				<IconButton onClick={onReload}>
					<RefreshIcon />
				</IconButton>
			)}
		</div>
	);
}
