import {
	HttpBadRequestError,
	HttpNotFoundError,
	HttpServerErrorError,
	HttpUnauthorizedError,
} from './http_errors';

export class HttpClient {
	constructor(params: Params) {
		this.baseUrl = params.baseUrl;
	}

	private baseUrl: string;

	async get(path: string, headers?: any): Promise<any> {
		const response = await fetch(this.baseUrl + path, {
			method: 'GET',
			headers: {
				...this.defaultHeaders,
				...(this.authHeaders ?? {}),
				...(headers ?? {}),
			},
		});
		return await this.handleResponse(response);
	}

	async post(path: string, body?: any, headers?: any): Promise<any> {
		const response = await fetch(this.baseUrl + path, {
			method: 'POST',
			headers: {
				...this.defaultHeaders,
				...(this.authHeaders ?? {}),
				...(headers ?? {}),
			},
			body: body ? JSON.stringify(body) : null,
		});
		return await this.handleResponse(response);
	}

	async put(path: string, body?: any, headers?: any): Promise<any> {
		const response = await fetch(this.baseUrl + path, {
			method: 'PUT',
			headers: {
				...this.defaultHeaders,
				...(this.authHeaders ?? {}),
				...(headers ?? {}),
			},
			body: body ? JSON.stringify(body) : null,
		});
		return await this.handleResponse(response);
	}

	async patch(path: string, body?: any, headers?: any): Promise<any> {
		const response = await fetch(this.baseUrl + path, {
			method: 'PATCH',
			headers: {
				...this.defaultHeaders,
				...(this.authHeaders ?? {}),
				...(headers ?? {}),
			},
			body: body ? JSON.stringify(body) : null,
		});
		return await this.handleResponse(response);
	}

	async delete(path: string, headers?: any): Promise<any> {
		const response = await fetch(this.baseUrl + path, {
			method: 'DELETE',
			headers: {
				...this.defaultHeaders,
				...(this.authHeaders ?? {}),
				...(headers ?? {}),
			},
		});
		return await this.handleResponse(response);
	}

	private get defaultHeaders(): any {
		return {
			'content-type': 'application/json',
			accept: 'application/json',
		};
	}

	private get authHeaders(): any {
		const accessToken = localStorage.getItem('accessToken');
		if (!accessToken) return {};
		return {
			Authorization: `Bearer ${accessToken}`,
		};
	}

	private async handleResponse(response: Response): Promise<any> {
		switch (response.status) {
			case 200:
				return (await response.json()) ?? null;
			case 204:
				return null;
			case 400:
				const body = await response.text();
				if (body.length === 0 || !body.startsWith('{')) {
					throw new HttpBadRequestError();
				}
				const json = JSON.parse(body);
				const name = json['name'];
				if (name == null) throw new HttpBadRequestError();
				throw new HttpBadRequestError(name);
			case 401:
				throw new HttpUnauthorizedError();
			case 404:
				throw new HttpNotFoundError();
			default:
				throw new HttpServerErrorError();
		}
	}
}

type Params = {
	baseUrl: string;
};
