import { Typography } from '@mui/material';
import { Group } from '../../../shared/entities';

export function GroupHeader({ group }: { group: Group }) {
	return (
		<div
			id={group.archorLink}
			style={{
				backgroundColor: '#f5f5f5',
				padding: '25px 15px',
				marginBottom: '15px',
				scrollMarginTop: '-1000px',
			}}
		>
			<Typography variant="h5">{group.name}</Typography>
		</div>
	);
}
