import { Box, CircularProgress, List } from '@mui/material';
import { useEffect, useState } from 'react';
import { Loading } from '../../../shared/components/Loading';
import { Group, Product } from '../../../shared/entities';
import { useDidUpdate } from '../../../shared/hooks/did_update';
import { ProductRepository } from '../../../shared/repositories';
import { pageSize } from '../constants';
import { GroupHeader } from './GroupHeader';
import { LoadMoreButton } from './LoadMoreButton';
import { ProductTile } from './ProductTile';

type Props = {
	productRepository: ProductRepository;
	group: Group;
	search: string;
};

export function GroupProducts({ productRepository, group, search }: Props) {
	const [products, setProducts] = useState<Product[]>([]);
	const [page, setPage] = useState({ value: 1 }); // Força o trigger do useEffect mesmo quando o valor não muda
	const [hasMore, setHasMore] = useState<boolean>(true);
	const [isLoading, setIsLoading] = useState<boolean>(true);

	useEffect(() => {
		loadProducts();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useDidUpdate(loadProducts, [page]);

	useDidUpdate(() => setPage({ value: 1 }), [search]);

	function loadProducts() {
		setIsLoading(true);
		productRepository
			.getProducts({
				groupId: group!.id,
				text: search,
				page: page.value,
				size: pageSize,
			})
			.then((newProducts) => {
				setHasMore(newProducts.length >= pageSize);
				if (page.value === 1) {
					setProducts(newProducts);
				} else {
					setProducts([...products, ...newProducts]);
				}
			})
			.finally(() => setIsLoading(false));
	}

	function loadMore() {
		setPage({ value: page.value + 1 });
	}

	if (isLoading && page.value === 1) {
		return <Loading />;
	}

	if (products.length === 0) {
		return null;
	}

	return (
		<List>
			<Box sx={{ display: 'flex', flexDirection: 'column' }}>
				<GroupHeader group={group} />
				{products.map((product, index) => {
					const isLast = index === products.length - 1;
					return (
						<div style={{ padding: '0 20px' }} key={`product+${product.id}`}>
							<ProductTile product={product} isLast={isLast} />
						</div>
					);
				})}

				{isLoading && (
					<div
						style={{
							marginTop: '16px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<CircularProgress disableShrink size={25} />
					</div>
				)}

				{hasMore && !isLoading && <LoadMoreButton onClick={loadMore} />}
			</Box>
		</List>
	);
}
