export class Establishment {
	uuid: string;
	name: string;
	table: number;
	type: EstablishmentType;
	phone: string;
	imageUrl: string;
	bannerUrl: string;

	private constructor(params: any) {
		Object.assign(this, params);
	}

	static fromJson(json: any): Establishment {
		return new Establishment({
			uuid: json.uuid,
			name: json.name,
			phone: json.phone,
			imageUrl: json.imageUrl,
			bannerUrl: json.bannerUrl,
			// imageUrl:
			// 'https://img.freepik.com/vetores-premium/modelo-de-logotipo-de-hamburguer_476121-29.jpg',
			// bannerUrl:
			// 	'https://img.lovepik.com/desgin_photo/45004/2570_detail.jpg!odetail650',
			table: json.table,
			type: json.type,
		});
	}

	public get isDelivery(): boolean {
		return this.type === 'WHATSAPP_DELIVERY';
	}

	public get isMenu(): boolean {
		return this.type === 'MENU';
	}

	public get isSelfService(): boolean {
		return this.type === 'SELF_SERVICE';
	}
}

type EstablishmentType = 'WHATSAPP_DELIVERY' | 'MENU' | 'SELF_SERVICE';
