declare global {
	export interface Number {
		toCurrency(): string;
	}
}

// eslint-disable-next-line
Number.prototype.toCurrency = function (this: number) {
	return new Intl.NumberFormat('pt-BR', {
		style: 'currency',
		currency: 'BRL',
	}).format(this);
};

export {};
