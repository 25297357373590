import { Box } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ErrorDialog, PrimaryButton } from '../../../../shared/components';
import { UserAppBar } from '../../../../shared/components/UserAppBar';
import { useBag } from '../../../../shared/contexts';
import { DeliveryAddressForm } from '../components/DeliveryAddressForm';
import { useDeliveryAddress } from '../contexts/DeliveryAddressContext';

type Props = {};

export function CreateDeliveryAddressPage({}: Props) {
	const navigate = useNavigate();
	const { addAndSetDeliveryAddress } = useBag();
	const { state, actions } = useDeliveryAddress();
	const [formError, setFormError] = useState<string | null>(null);

	async function save() {
		if (state.deliveryAvailabilityError) {
			return setFormError(state.deliveryAvailabilityError);
		}
		if (!actions.validate()) return;
		const address = actions.toEntity();
		addAndSetDeliveryAddress(address, state.deliveryFee!, state.minOrderValue!);
		navigate('/bag/delivery-address');
	}

	return (
		<>
			<UserAppBar title="Novo endereço" />
			<ErrorDialog
				open={!!formError}
				close={() => setFormError(null)}
				error={formError}
			/>
			<Box padding="20px">
				<DeliveryAddressForm />
				<PrimaryButton label="Salvar" onClick={save} />
			</Box>
		</>
	);
}
