import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Card, CardActionArea, Typography } from '@mui/material';
import { DeliveryAddress } from '../../../../../shared/entities/delivery_address';
import DeliveryAddressPopupMenu from './DeliveryAddressPopupMenu';

type Props = {
	address: DeliveryAddress;
	onSelected: () => void;
	onSelectAsMain: () => void;
	onDelete: () => void;
	onEdit: () => void;
};

export const DeliveryAddressTile = ({
	address,
	onSelected,
	onSelectAsMain,
	onDelete,
	onEdit,
}: Props) => {
	return (
		<Card variant="outlined" style={{ marginBottom: '16px' }}>
			<CardActionArea>
				<Box
					key={address.id}
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<Box padding="8px 12px" onClick={onSelected} width="100%">
						<Typography variant="subtitle1" fontWeight="600">
							{address.name}
						</Typography>
						<Typography variant="subtitle2" color="grey.600">
							{address.formatted}
						</Typography>
					</Box>

					<Box
						display="flex"
						flexDirection="column"
						alignItems="end"
						padding="8px 12px"
						marginBottom="4px"
					>
						{address.isMain && (
							<Box display="flex" alignItems="center">
								<CheckCircleIcon
									color="primary"
									fontSize="small"
									style={{ marginRight: '8px' }}
								/>
								<Typography variant="caption" color="primary.main">
									Principal
								</Typography>
							</Box>
						)}
						{address.isPending && (
							<Box display="flex" alignItems="center">
								<Typography variant="caption" color="primary.main">
									Pendente
								</Typography>
							</Box>
						)}
						<DeliveryAddressPopupMenu
							address={address}
							onSelectAsMain={onSelectAsMain}
							onDelete={onDelete}
							onEdit={onEdit}
						/>
					</Box>
				</Box>
			</CardActionArea>
		</Card>
	);
};
