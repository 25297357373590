import { Typography } from '@mui/material';

export function EstablishmentOffline() {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column',
				alignItems: 'center',
				height: '100vh',
			}}
		>
			<Typography variant="subtitle1" align="center">
				Este estabelecimento está fora do horário de atendimento.
			</Typography>
		</div>
	);
}
