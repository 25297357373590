import {
	InvalidOrExpiredTokenError,
	UnexpectedError,
} from '../../shared/errors';
import { User } from '../entities';
import { AuthType } from '../entities/auth_type';
import { HttpBadRequestError, HttpClient } from '../http_client';

export class AuthRepository {
	constructor(private httpClient: HttpClient) {}

	async sendVerificationCode(phone: string, type: AuthType): Promise<void> {
		const path = `/user/send-token`;
		await this.httpClient.post(path, { phone, type });
	}

	async verifyCode(phone: string, code: string): Promise<User> {
		try {
			const path = `/user/verify-token`;
			const json = await this.httpClient.post(path, { phone, token: code });
			const { user, accessToken } = json;
			localStorage.setItem('accessToken', accessToken);
			return User.fromJson(user);
		} catch (error) {
			if (error instanceof HttpBadRequestError) {
				if (error.name === 'InvalidOrExpiredToken') {
					throw new InvalidOrExpiredTokenError();
				}
			}
			throw new UnexpectedError();
		}
	}

	async signUp(phone: string): Promise<User> {
		try {
			const path = `/user/signup`;
			const json = await this.httpClient.post(path, { phone });
			const { user, accessToken } = json;
			localStorage.setItem('accessToken', accessToken);
			return User.fromJson(user);
		} catch (_) {
			throw new UnexpectedError();
		}
	}

	isLogged(): boolean {
		return localStorage.getItem('accessToken') !== null;
	}

	logout(): void {
		this.httpClient.post('/user/logout');
		localStorage.removeItem('accessToken');
	}
}
