import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { useDeliveryAddress } from '../contexts/DeliveryAddressContext';

export default function RegionInput() {
	const { state, actions } = useDeliveryAddress();
	return (
		<Autocomplete
			fullWidth={true}
			style={{ marginTop: '10px' }}
			renderOption={(props, option) => (
				<li
					{...props}
					style={{ ...props.style, height: '40px' }}
					key={option.name}
				>
					{option.name}
				</li>
			)}
			isOptionEqualToValue={(option, value) => option.name === value.name}
			getOptionLabel={(option) => option.name}
			defaultValue={state.region.value}
			onChange={(_, value) => actions.onRegionChange(value!)}
			options={state.district.value?.regions ?? []}
			noOptionsText="Apenas referências cadastradas pelo estabelecimento são permitidas. Selecione uma referência da lista."
			renderInput={(params) => (
				<TextField
					{...params}
					label="Referência"
					variant="standard"
					onBlur={actions.onBlurRegion}
					error={state.region.error.trim().length > 0}
					helperText={state.region.error.trim()}
				/>
			)}
		/>
	);
}
