import { Card, Typography } from '@mui/material';

type Props = {
	fee: number;
};

export const DeliveryFeeCard = ({ fee }: Props) => {
	return (
		<Card variant="outlined" style={{ padding: '20px', marginTop: '20px' }}>
			<Typography variant="h6">Taxa de entrega</Typography>
			<Typography>{fee.toCurrency()}</Typography>
		</Card>
	);
};
