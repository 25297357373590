import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EstablishmentAppBar } from '../../../shared/components';
import { DocumentInput } from '../../../shared/components/DocumentInput';
import { useBag, useUser } from '../../../shared/contexts';
import { Validator } from '../../../shared/validator';
import { BagFooter } from '../components';

export function CustomerDocumentPage() {
	const navigate = useNavigate();
	const user = useUser().state.user;
	const { state, updateCustomerDocument } = useBag();
	const [document, setDocument] = useState({ value: '', error: '' });
	const documentValidator = new Validator().required().cpf();

	useEffect(() => {
		if (user?.document) {
			setDocument({ value: user.document, error: '' });
		}
	}, []);

	function onDocumentChanged(value: string) {
		setDocument({ value, error: documentValidator.execute(value) });
	}

	function save() {
		const documentError = documentValidator.execute(document.value);
		if (documentError) {
			return setDocument({ ...document, error: documentError });
		}
		updateCustomerDocument(document.value);
		navigate('/bag/confirm');
	}

	return (
		<>
			<EstablishmentAppBar />
			<Box padding="20px">
				<Typography variant="h5">Informe seu CPF</Typography>
				<Box marginTop="24px">
					<DocumentInput
						label=""
						value={document.value}
						onChange={onDocumentChanged}
						error={document.error}
					/>
				</Box>
				<BagFooter total={state.total} onNext={save} />
			</Box>
		</>
	);
}
