import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EstablishmentAppBar } from '../../../../shared/components';
import { CurrencyInput } from '../../../../shared/components/CurrencyInput';
import { useBag, useEstablishment } from '../../../../shared/contexts';
import { BagFooter } from '../../components';

export function PaymentChangePage() {
	const navigate = useNavigate();
	const { state, setChangeFor } = useBag();
	const establishmentConfig = useEstablishment().state.config;
	const [error, setError] = useState<string | null>(null);

	const nextStep = () => {
		if (error) return;
		if (
			establishmentConfig.requestDocument &&
			state.deliveryMethod === 'delivery'
		) {
			return navigate('/bag/customer-document');
		}
		navigate('/bag/confirm');
	};

	useEffect(() => {
		if (state.changeFor > 0 && state.changeFor <= state.total) {
			setError('O troco deve ser maior que o total do pedido');
		} else {
			setError(null);
		}
	}, [state.changeFor]);

	return (
		<>
			<EstablishmentAppBar />
			<Box padding="20px">
				<Typography variant="h5">Precisa de troco?</Typography>
				<Typography variant="subtitle2" color="grey.600">
					Informe para quanto precisa de troco
				</Typography>
				<Box marginTop="8px">
					<CurrencyInput
						value={state.changeFor}
						onChange={setChangeFor}
						error={error}
					/>
				</Box>
				<BagFooter total={state.total} onNext={nextStep} />
			</Box>
		</>
	);
}
