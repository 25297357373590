import { ArrowForward } from '@mui/icons-material';
import { Box, Button, Divider, Typography } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import { useNavigate } from 'react-router-dom';
import { EstablishmentAppBar } from '../../../shared/components';
import { useUser } from '../../../shared/contexts';
import { useEstablishment } from '../../../shared/contexts/EstablishmentContext';

export function HomeAppBar() {
	const { establishment } = useEstablishment().state;

	if (!establishment.bannerUrl) {
		return (
			<AppBar>
				<EstablishmentAppBar />
				<UserAppBar />
				<EstablishmentClosedAppBar />
			</AppBar>
		);
	}
	return (
		<AppBar>
			<img
				style={{
					objectFit: 'cover',
					width: '100%',
				}}
				src={establishment.bannerUrl}
				alt={establishment.name}
				height="180px"
				loading="lazy"
			/>
			<UserAppBar />
			<EstablishmentClosedAppBar />
			<Typography
				variant="h5"
				component="div"
				sx={{ flexGrow: 1, margin: '20px 20px 0 20px' }}
				fontWeight="bold"
			>
				{establishment.name}
				{establishment.isSelfService && (
					<Typography variant="subtitle2" component="div">
						Mesa {establishment.table}
					</Typography>
				)}
			</Typography>
		</AppBar>
	);
}

function UserAppBar() {
	const navigate = useNavigate();
	const { user, isLogged } = useUser().state;
	const { establishment } = useEstablishment().state;
	if (!isLogged || !establishment.isDelivery) return null;
	return (
		<>
			<Box
				sx={{ padding: '8px 16px' }}
				display="flex"
				alignItems="center"
				justifyContent="space-between"
			>
				<Typography variant="h6">Olá, {user!.firstName}</Typography>
				<Button
					color="primary"
					onClick={() => navigate('/profile')}
					endIcon={<ArrowForward />}
				>
					Ver perfil
				</Button>
			</Box>
			<Divider />
		</>
	);
}

function EstablishmentClosedAppBar() {
	const { establishmentClosed } = useEstablishment().state;
	if (!establishmentClosed) return null;
	return (
		<>
			<Box sx={{ padding: '4px 16px' }} bgcolor="#d5ed58">
				<Typography variant="subtitle2" align="center" fontWeight="500">
					Este estabelecimento está fechado no momento
				</Typography>
			</Box>
			<Divider />
		</>
	);
}
