import { SessionExpiredError } from '../../shared/errors';
import { HttpClient } from '../http_client';
import { BaseRepository } from './base_repository';

export class WaiterRepository extends BaseRepository {
	constructor(private httpClient: HttpClient) {
		super();
	}

	async callWaiter(input: CallWaiterInput): Promise<void> {
		const session = this.getSession();
		if (!session || session.isExpired) throw new SessionExpiredError();
		const path = `/establishments/${session.establishment.uuid}/tables/${session.establishment.table}/call-waiter`;
		await this.httpClient.post(path, {
			message: input.message.trim(),
			services: input.services.map((item) => ({
				name: item.name,
				quantity: item.quantity,
			})),
		});
	}
}

type CallWaiterInput = {
	message: string;
	services: { name: string; quantity: number }[];
};
