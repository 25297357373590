import {
	Box,
	Card,
	CardActionArea,
	CardContent,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useEstablishment } from '../../../shared/contexts';
import { Product } from '../../../shared/entities';
import { EstablishmentClosedDialog } from './EstablishmentClosedDialog';

export function ProductTile({
	product,
	isLast,
}: {
	product: Product;
	isLast: boolean;
}) {
	const navigate = useNavigate();
	const [closedEstablishmentDialogOpen, setClosedEstablishmentDialogOpen] =
		useState(false);
	const { establishmentClosed } = useEstablishment().state;

	function toProduct() {
		if (establishmentClosed) {
			return setClosedEstablishmentDialogOpen(true);
		}
		navigate(`/products/${product.id}`);
	}

	return (
		<>
			<EstablishmentClosedDialog
				open={closedEstablishmentDialogOpen}
				close={() => setClosedEstablishmentDialogOpen(false)}
			/>
			<Card
				onClick={toProduct}
				variant="outlined"
				style={{ marginBottom: isLast ? '0' : '16px' }}
			>
				<CardActionArea>
					<CardContent style={{ padding: '16px' }}>
						<div
							style={{
								display: 'flex',
								justifyContent: 'space-between',
								alignItems: 'center',
							}}
						>
							<Box>
								<Typography variant="h6" style={{ padding: '2px 0' }}>
									{product.name}
								</Typography>
								<Typography variant="body2" style={{ padding: '2px 0' }}>
									{product.observation}
								</Typography>
								<Typography
									variant="subtitle2"
									style={{ padding: '2px 0' }}
									color="primary.main"
									fontWeight="bold"
								>
									{product.price.toCurrency()}
								</Typography>
							</Box>
							{product.imageUrl && (
								<img
									style={{
										borderRadius: '4px',
										marginLeft: '10px',
										objectFit: 'cover',
									}}
									width="60"
									height="60"
									src={product!.imageUrl}
									srcSet={product!.imageUrl}
									alt={product.name}
									loading="lazy"
								/>
							)}
						</div>
					</CardContent>
				</CardActionArea>
			</Card>
		</>
	);
}
