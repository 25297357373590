import { Box, Divider, Typography } from '@mui/material';

export function SuccessCard({ message }: { message: string }) {
	return (
		<>
			<Box display="flex" height="50px" marginBottom="20px">
				<img src="/icons/check.png" alt="success icon" />
				<Box marginLeft="15px">
					<Typography variant="h5" textAlign="left">
						Obrigado!
					</Typography>
					<Typography variant="subtitle2" textAlign="left">
						{message}
					</Typography>
				</Box>
			</Box>
			<Divider />
		</>
	);
}
