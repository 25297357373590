import { Box, Divider, List, Typography } from '@mui/material';
import { WaiterService } from '..';
import { QuantityManager } from '../../../shared/components/QuantityManager';

export function Services({
	services,
	onIncrement,
	onDecrement,
}: {
	services: WaiterService[];
	onIncrement: (service: WaiterService) => void;
	onDecrement: (service: WaiterService) => void;
}) {
	const list = services.map((service) => (
		<Box key={service.name}>
			<Box
				height="50px"
				display="flex"
				alignItems="center"
				justifyContent="space-between"
			>
				<Typography variant="h6" style={{ padding: '5px 0' }}>
					{service.name}
				</Typography>
				<QuantityManager
					quantity={service.quantity}
					onIncrement={() => onIncrement(service)}
					onDecrement={() => onDecrement(service)}
				/>
			</Box>
			<Divider />
		</Box>
	));

	return (
		<Box sx={{ width: '100%', marginBottom: '30px' }}>
			<List>{list}</List>
		</Box>
	);
}
