import { AdditionalQuantity } from '../../shared/dto';

export class ProductSelection {
	constructor(
		public id: number,
		public name: string,
		public price: number,
		public additionals: AdditionalQuantity[],
		public quantity: number,
		public observations: string,
		public tipPercent: number = 0
	) {}

	get tip(): number {
		return this.total * (this.tipPercent / 100);
	}

	get total(): number {
		const totalAdditionals = this.additionals.reduce(
			(total, item) => total + item.total,
			0
		);
		return (this.price + totalAdditionals) * this.quantity;
	}

	static fromJson(json: any): ProductSelection {
		return new ProductSelection(
			json.id,
			json.name,
			json.price,
			json.additionals.map(AdditionalQuantity.fromJson),
			json.quantity,
			json.observations,
			json.tipPercent
		);
	}

	toJson(): any {
		return {
			id: this.id,
			name: this.name,
			price: this.price,
			additionals: this.additionals,
			quantity: this.quantity,
			observations: this.observations,
			tipPercent: this.tipPercent,
		};
	}
}
