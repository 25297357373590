import { cpf } from 'cpf-cnpj-validator';

export class Validator {
	private validators: any[] = [];

	required(): Validator {
		this.validators.push((value: any) => {
			if (value.trim().length === 0) {
				return 'Campo obrigatório';
			}
			return '';
		});
		return this;
	}

	phone(): Validator {
		this.validators.push((value: any) => {
			if (value.onlyNumbers().length !== 11) {
				return 'Número de telefone inválido';
			}
			return '';
		});
		return this;
	}

	verificationCode(): Validator {
		const regex = /^\d{3}-\d{3}$/;
		this.validators.push((value: any) => {
			if (!regex.test(value)) {
				return 'Código de verificação inválido';
			}
			return '';
		});
		return this;
	}

	minLength(length: number): Validator {
		this.validators.push((value: any) => {
			if (value.trim().length < length) {
				return `Mínimo de ${length} caracteres`;
			}
			return '';
		});
		return this;
	}

	zipCode(): Validator {
		const regex = /^\d{5}-\d{3}$/;
		this.validators.push((value: any) => {
			if (!regex.test(value)) {
				return 'CEP inválido';
			}
			return '';
		});
		return this;
	}

	cpf(): Validator {
		this.validators.push((value: any) => {
			if (value.length === 0 || cpf.isValid(value)) return '';
			return 'CPF inválido';
		});
		return this;
	}

	execute(value: string): string {
		for (const validator of this.validators) {
			const error = validator(value);
			if (error.length > 0) {
				return error;
			}
		}
		return '';
	}
}
