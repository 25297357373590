import { CircularProgress } from '@mui/material';

export function Loading() {
	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				height: '100vh',
			}}
		>
			<CircularProgress disableShrink />
		</div>
	);
}
