import { Box, Typography } from '@mui/material';
import { useEstablishment } from '../../../shared/contexts';
import { useBag } from '../../../shared/contexts/BagContext';
import { DeliveryMethod } from '../../../shared/entities';

export function Trailer() {
	const { establishment, config } = useEstablishment().state;
	const { state } = useBag();

	return (
		<Box>
			<Typography
				margin="20px 0 5px 0"
				variant="h6"
				fontWeight="bold"
				textAlign="left"
			>
				Resumo
			</Typography>
			<Box padding="20px" bgcolor="grey.100" borderRadius="10px">
				<Box display="flex" justifyContent="space-between">
					<Typography variant="subtitle2">Subtotal</Typography>
					<Typography variant="subtitle2">
						{state.subtotal.toCurrency()}
					</Typography>
				</Box>
				{establishment.isDelivery &&
					state.deliveryMethod === DeliveryMethod.delivery && (
						<Box display="flex" justifyContent="space-between" margin="10px 0">
							<Typography variant="subtitle2">Taxa de entrega</Typography>
							<Typography variant="subtitle2">
								{state.deliveryFee!.toCurrency()}
							</Typography>
						</Box>
					)}
				{establishment.isSelfService && config.tipPercent > 0 && (
					<Box display="flex" justifyContent="space-between" margin="10px 0">
						<Typography variant="subtitle2">
							Gorjeta ({config.tipPercent}%)
						</Typography>
						<Typography variant="subtitle2">
							{state.totalTip.toCurrency()}
						</Typography>
					</Box>
				)}
				<Box display="flex" justifyContent="space-between">
					<Typography variant="subtitle2" fontWeight="bold">
						Total
					</Typography>
					<Typography variant="subtitle2" fontWeight="bold">
						{state.total.toCurrency()}
					</Typography>
				</Box>
			</Box>
		</Box>
	);
}
