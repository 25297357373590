import { TextField } from '@mui/material';

export function TextBox({
	text,
	onChange,
}: {
	text: string;
	onChange: (text: string) => void;
}) {
	return (
		<TextField
			fullWidth={true}
			label="Possui dúvidas ou deseja outros serviços?"
			multiline
			style={{ height: '50px' }}
			value={text}
			variant="standard"
			inputProps={{ maxLength: 180 }}
			onChange={(e) => onChange(e.target.value)}
		/>
	);
}
