import InfoIcon from '@mui/icons-material/Info';
import { Box, InputAdornment, TextField, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import InputMask from 'react-input-mask';
import { AddressMaxLength } from '../../../../shared/entities/address';
import { DeliveryAddressMaxLength } from '../../../../shared/entities/delivery_address';
import {
	ZipCodeSearchState,
	useDeliveryAddress,
} from '../contexts/DeliveryAddressContext';
import NeighborhoodInput from './NeighborhoodInput';
import RegionInput from './RegionInput';

export function DeliveryAddressForm() {
	const { state, actions } = useDeliveryAddress();
	return (
		<Box>
			<TextField
				fullWidth={true}
				label="Nome do endereço"
				error={state.name.error.trim().length > 0}
				helperText={state.name.error.trim()}
				variant="standard"
				value={state.name.value}
				onChange={(e) => actions.onNameChange(e.target.value)}
				inputProps={{
					maxLength: DeliveryAddressMaxLength.name,
				}}
			/>
			<InputMask
				mask="99999-999"
				value={state.zipCode.value}
				onChange={(e) => actions.onZipCodeChange(e.target.value)}
			>
				{(inputProps: any) => (
					<TextField
						{...inputProps}
						style={{ marginTop: '10px' }}
						fullWidth={true}
						label="CEP"
						variant="standard"
						error={state.zipCode.error.trim().length > 0}
						helperText={state.zipCode.error.trim()}
						type="tel"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									{state.zipCodeSearchState === ZipCodeSearchState.LOADING && (
										<CircularProgress size={18} />
									)}
								</InputAdornment>
							),
						}}
					/>
				)}
			</InputMask>
			{state.zipCodeSearchState === ZipCodeSearchState.SEARCHED && (
				<>
					<TextField
						fullWidth={true}
						style={{ marginTop: '10px' }}
						label="Rua"
						variant="standard"
						value={state.street.value}
						onChange={(e) => actions.onStreetChange(e.target.value)}
						error={state.street.error.trim().length > 0}
						helperText={state.street.error.trim()}
						disabled={!state.isGeneralZipCode}
						inputProps={{
							maxLength: AddressMaxLength.street,
						}}
					/>
					<TextField
						fullWidth={true}
						style={{ marginTop: '10px' }}
						label="Número"
						error={state.number.error.trim().length > 0}
						helperText={state.number.error.trim()}
						variant="standard"
						value={state.number.value}
						onChange={(e) => actions.onNumberChange(e.target.value)}
						inputProps={{
							maxLength: AddressMaxLength.number,
						}}
					/>
					<TextField
						fullWidth={true}
						style={{ marginTop: '10px' }}
						label="Complemento"
						variant="standard"
						value={state.complement}
						onChange={(e) => actions.onComplementChange(e.target.value)}
						inputProps={{
							maxLength: AddressMaxLength.complement,
						}}
					/>
					<NeighborhoodInput />
					{state.district?.value?.regions?.length ? <RegionInput /> : null}
					<TextField
						fullWidth={true}
						style={{ marginTop: '10px' }}
						label="Cidade"
						variant="standard"
						value={state.city.name}
						disabled={true}
					/>
					<TextField
						fullWidth={true}
						style={{ marginTop: '10px' }}
						label="Estado"
						variant="standard"
						value={state.state}
						disabled={true}
					/>
				</>
			)}

			{state.deliveryFee ? (
				<Typography variant="subtitle1" style={{ marginTop: '30px' }}>
					Taxa de entrega: {state.deliveryFee.toCurrency()}
				</Typography>
			) : null}

			{state.deliveryAvailabilityError ? (
				<Typography
					variant="subtitle1"
					style={{ marginTop: '30px' }}
					color="error"
				>
					{state.deliveryAvailabilityError}
				</Typography>
			) : null}

			{state.minOrderValueError ? (
				<div
					style={{ display: 'flex', alignItems: 'center', marginTop: '12px' }}
				>
					<InfoIcon
						fontSize="small"
						style={{ marginRight: '8px', color: '#c99300' }}
					/>
					<Typography variant="subtitle1" color="#c99300">
						{state.minOrderValueError}
					</Typography>
				</div>
			) : null}
		</Box>
	);
}
