import { Divider, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { Product } from '../../../shared/entities';
import { Additionals } from './Additionals';

export function ProductComponent({ product }: { product: Product }) {
	return (
		<Box sx={{ width: '100%' }}>
			{product.imageUrl && (
				<img
					width="100%"
					height="100%"
					src={product!.imageUrl}
					srcSet={product!.imageUrl}
					alt={product.name}
					loading="lazy"
				/>
			)}
			<div style={{ padding: '20px' }}>
				<Box display="flex" justifyContent="space-between">
					<Typography variant="h5">{product.name}</Typography>
					<Typography variant="h5">{product.price.toCurrency()}</Typography>
				</Box>
				{product.observation && (
					<Typography variant="body1" style={{ padding: '15px 0' }}>
						{product.observation}
					</Typography>
				)}
				{!product.observation && <div style={{ paddingBottom: '20px' }} />}
				<Divider style={{ marginBottom: '20px' }} />
				{product.additionalGroups && <Additionals />}
			</div>
		</Box>
	);
}
