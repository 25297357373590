import { Box, TextField, Typography } from '@mui/material';

export function ObservationInput({
	value,
	onChange,
}: {
	value: string;
	onChange: (value: string) => void;
}) {
	return (
		<Box padding="0 20px">
			<Typography variant="subtitle2" component="div" fontWeight="bold">
				Alguma observação?
			</Typography>
			<TextField
				fullWidth={true}
				multiline
				value={value}
				variant="standard"
				inputProps={{ maxLength: 180 }}
				onChange={(e) => onChange(e.target.value)}
			/>
		</Box>
	);
}
