import { Box, Button, CircularProgress } from '@mui/material';
import { useState } from 'react';
import waiterCalledImage from '../../shared/assets/images/waiter-called.png';
import { ConfirmationDialog } from '../../shared/components/ConfirmationDialog';
import { ErrorDialog } from '../../shared/components/ErrorDialog';
import { SuccessCard } from '../../shared/components/SuccessCard';
import { useEstablishment } from '../../shared/contexts/EstablishmentContext';
import { AppError, SessionExpiredError } from '../../shared/errors';
import { WaiterRepository } from '../../shared/repositories';
import { Header } from './components/Header';
import { Services } from './components/Services';
import { TextBox } from './components/TextBox';

type Props = {
	waiterRepository: WaiterRepository;
};

export type WaiterService = {
	name: string;
	quantity: number;
};

export function WaiterPage({ waiterRepository }: Props) {
	const { setSessionAsExpired } = useEstablishment();
	const [text, setText] = useState<string>('');
	const [services, setServices] = useState([
		{ name: 'Copo extra', quantity: 0 },
		{ name: 'Copo com gelo', quantity: 0 },
		{ name: 'Talheres extra', quantity: 0 },
		{ name: 'Prato extra', quantity: 0 },
	]);
	const [showEmptySubmitConfirmation, setShowEmptySubmitConfirmation] =
		useState<boolean>(false);
	const [isCallingWaiter, setIsCallingWaiter] = useState<boolean>(false);
	const [callWaiterError, setCallWaiterError] = useState<AppError | null>(null);
	const [isWaiterCalled, setIsWaiterCalled] = useState<boolean>(false);

	const incrementQuantity = (service: WaiterService): void => {
		if (service.quantity < 99) service.quantity++;
		updateServices(service);
	};

	const decrementQuantity = (service: WaiterService): void => {
		if (service.quantity > 0) service.quantity--;
		updateServices(service);
	};

	const updateServices = (service: WaiterService): void => {
		const index = services.indexOf(service);
		services[index] = service;
		setServices([...services]);
	};

	async function handleSubmit({ submitIfEmpty = false } = {}): Promise<void> {
		const hasText = text.trim().length > 0;
		const isAnyServiceSelected =
			services.filter((service) => service.quantity > 0).length > 0;
		const isEmpty = !hasText && !isAnyServiceSelected;
		if (isEmpty && !submitIfEmpty) {
			setShowEmptySubmitConfirmation(true);
			return;
		}
		setCallWaiterError(null);
		setIsCallingWaiter(true);
		waiterRepository
			.callWaiter({
				message: text.trim(),
				services: isEmpty
					? []
					: services.map((service) => ({
							name: service.name,
							quantity: service.quantity,
					  })),
			})
			.then((_) => setIsWaiterCalled(true))
			.catch((error) => {
				if (error instanceof SessionExpiredError) return setSessionAsExpired();
				setCallWaiterError(error as AppError);
			})
			.finally(() => setIsCallingWaiter(false));
	}

	function reset() {
		setText('');
		for (const service of services) service.quantity = 0;
		setIsWaiterCalled(false);
	}

	return (
		<Box padding="16px 20px 0 20px">
			{isWaiterCalled ? (
				<>
					<SuccessCard message="Seu pedido foi criado com sucesso." />
					<Box
						display="flex"
						justifyContent="center"
						alignItems="center"
						height="60vh"
					>
						<img src={waiterCalledImage} alt="Gdoorzinho" width="250px" />
					</Box>
					<Box
						sx={{
							position: 'fixed',
							bottom: '75px',
							left: '20px',
							right: '20px',
						}}
					>
						<Button
							variant="contained"
							disableElevation
							onClick={reset}
							fullWidth={true}
							style={{ height: '50px', textTransform: 'unset' }}
						>
							Fazer um novo pedido
						</Button>
					</Box>
				</>
			) : (
				<>
					<Header />
					<Services
						services={services}
						onIncrement={incrementQuantity}
						onDecrement={decrementQuantity}
					/>
					<TextBox text={text} onChange={setText} />
					<Box marginTop="35px" width="100%">
						{isCallingWaiter && (
							<CircularProgress
								disableShrink
								style={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									margin: 'auto',
								}}
							/>
						)}
						{!isCallingWaiter && (
							<Button
								variant="contained"
								disableElevation
								onClick={() => handleSubmit()}
								fullWidth={true}
								style={{
									height: '50px',
									textTransform: 'unset',
								}}
							>
								Chamar garçom
							</Button>
						)}
					</Box>

					<ErrorDialog
						error={callWaiterError?.message}
						open={callWaiterError != null}
						close={() => setCallWaiterError(null)}
					/>
					<ConfirmationDialog
						title="Formulário vazio"
						body="Você não selecionou nenhum serviço ou mensagem. Deseja enviar mesmo assim?"
						onConfirm={() => handleSubmit({ submitIfEmpty: true })}
						open={showEmptySubmitConfirmation}
						setOpen={() => setShowEmptySubmitConfirmation(false)}
					/>
				</>
			)}
		</Box>
	);
}
