import CloseOutlined from '@mui/icons-material/CloseOutlined';
import { InputAdornment } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import * as React from 'react';
import { AddressMaxLength } from '../../../../shared/entities/address';
import { District } from '../../../../shared/entities/district';
import { useDeliveryAddress } from '../contexts/DeliveryAddressContext';
import { UnregisteredNeighborhoodDialog } from './UnregisteredNeighborhoodDialog';

export default function NeighborhoodInput() {
	const { state, actions } = useDeliveryAddress();
	const [options, setOptions] = React.useState<District[]>([]);
	const filterOptions = createFilterOptions<District>();

	React.useEffect(() => {
		const districts = actions.loadDistricts();
		setOptions(districts);
	}, []);

	const isRegisteredDistrict = !state.isUnregisteredDistrict;
	const district = state.district.value;
	const showInput =
		isRegisteredDistrict &&
		(!isRegisteredDistrict || district === null || district.isRegistered);
	return (
		<>
			<UnregisteredNeighborhoodDialog />
			{showInput && (
				<Autocomplete
					fullWidth={true}
					style={{ marginTop: '10px' }}
					renderOption={(props, option) => (
						<li
							{...props}
							style={{ ...props.style, height: '40px' }}
							key={option.neighborhood}
						>
							{option.neighborhood}
						</li>
					)}
					isOptionEqualToValue={(option, value) =>
						option.neighborhood === value.neighborhood
					}
					getOptionLabel={(option) => option.neighborhood}
					defaultValue={state.district.value}
					filterOptions={(options, params) => {
						const results = filterOptions(options, params);
						if (state.deliveryConfig.deliverOnUnregisteredRegion) {
							results.push(District.unregistered());
						}
						return results;
					}}
					onChange={(_, value) => actions.onDistrictChange(value!)}
					options={options}
					noOptionsText={
						state.deliveryConfig.deliverOnUnregisteredRegion
							? 'Nenhum bairro encontrado'
							: options.length === 0
							? 'Nenhum bairro encontrado'
							: 'Apenas bairros cadastrados pelo estabelecimento são permitido. Selecione um bairro da lista.'
					}
					renderInput={(params) => (
						<TextField
							{...params}
							label="Bairro"
							variant="standard"
							error={state.district.error.trim().length > 0}
							helperText={state.district.error.trim()}
							onBlur={actions.onBlurDistrict}
						/>
					)}
				/>
			)}
			{state.district?.value?.isUnregistered || state.isUnregisteredDistrict ? (
				<TextField
					fullWidth={true}
					style={{ marginTop: '10px' }}
					label="Bairro"
					variant="standard"
					value={state.neighborhood.value}
					onChange={(e) => actions.onNeighborhoodChange(e.target.value)}
					error={state.neighborhood.error.trim().length > 0}
					helperText={state.neighborhood.error.trim()}
					InputProps={{
						endAdornment: district !== null && district.isUnregistered && (
							<InputAdornment position="end">
								<CloseOutlined onClick={() => actions.removeNeighborhood()} />
							</InputAdornment>
						),
					}}
					inputProps={{
						maxLength: AddressMaxLength.neighborhood,
					}}
				/>
			) : null}
		</>
	);
}
