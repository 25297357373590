import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const AddMoreItemsButton = () => {
	const navigate = useNavigate();

	return (
		<Button variant="text" fullWidth={true} onClick={() => navigate('/')}>
			Adicionar mais itens
		</Button>
	);
};
