import { Card, Typography } from '@mui/material';

type Props = {
	minOrderValue: number;
};

export const MinOrderValueCard = ({ minOrderValue }: Props) => {
	return (
		<Card
			variant="outlined"
			style={{
				padding: '20px',
				marginTop: '20px',
				backgroundColor: '#ffff8c',
			}}
		>
			<Typography variant="h6">Valor mínimo do pedido</Typography>
			<Typography>
				O pedido mínimo deste estabelecimento é de{' '}
				<span style={{ fontWeight: 'bold' }}>{minOrderValue.toCurrency()}</span>{' '}
				sem contar com a taxa de entrega.
			</Typography>
		</Card>
	);
};
