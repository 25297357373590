import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

export function ErrorDialog({
	error,
	open,
	close,
}: {
	error: string | null | undefined;
	open: boolean;
	close: () => void;
}) {
	return (
		<Dialog open={open} onClose={close}>
			<DialogTitle>{error}</DialogTitle>
			<DialogActions>
				<Button onClick={() => close()}>Ok</Button>
			</DialogActions>
		</Dialog>
	);
}
