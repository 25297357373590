import ShoppingBagOutlinedIcon from '@mui/icons-material/ShoppingBagOutlined';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { useBag } from '../../../shared/contexts/BagContext';
import { useEstablishment } from '../../../shared/contexts/EstablishmentContext';

export function BagCard() {
	const { state } = useBag();
	const { establishment } = useEstablishment().state;

	return (
		<Link
			to={{
				pathname: establishment.isDelivery ? '/bag' : '/bag/confirm',
			}}
		>
			<Box
				display="flex"
				flexDirection="row"
				justifyContent="space-between"
				alignItems="center"
				height="55px"
				boxSizing="border-box"
				sx={{
					position: 'fixed',
					bottom: establishment.isSelfService ? '55px' : 0,
					left: 0,
					right: 0,
				}}
				bgcolor="primary.main"
				padding="15px"
			>
				<ShoppingBagOutlinedIcon style={{ color: 'white' }} />
				<Typography variant="subtitle1" color="white">
					Ver pedido
				</Typography>
				<Typography variant="subtitle1" color="white">
					{state.total.toCurrency()}
				</Typography>
			</Box>
		</Link>
	);
}
