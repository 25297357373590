import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
} from '@mui/material';
import { useState } from 'react';
import { AddressMaxLength } from '../../../../shared/entities/address';
import { useDidUpdate } from '../../../../shared/hooks/did_update';
import { Validator } from '../../../../shared/validator';
import { useDeliveryAddress } from '../contexts/DeliveryAddressContext';

export function UnregisteredNeighborhoodDialog() {
	const [open, setOpen] = useState(false);
	const { state, actions } = useDeliveryAddress();
	const [neighborhood, setNeighborhood] = useState({ value: '', error: '' });
	const validator = new Validator().required();

	function onNeighborhoodChange(value: string) {
		setNeighborhood({ value, error: validator.execute(value) });
	}

	function onSave() {
		if (neighborhood.error.trim().length === 0) {
			actions.onNeighborhoodChange(neighborhood.value);
			setNeighborhood({ value: '', error: '' });
			setOpen(false);
		}
	}

	useDidUpdate(() => {
		if (state.district?.value?.isUnregistered || state.isUnregisteredDistrict) {
			setOpen(true);
		}
	}, [state.district?.value?.isUnregistered, state.isUnregisteredDistrict]);

	return (
		<Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
			<DialogTitle style={{ marginBottom: '24px' }}>
				Qual seu bairro?
			</DialogTitle>
			<DialogContent>
				<TextField
					fullWidth={true}
					variant="standard"
					value={neighborhood.value}
					onChange={(e) => onNeighborhoodChange(e.target.value)}
					error={neighborhood.error.trim().length > 0}
					helperText={neighborhood.error.trim()}
					inputProps={{
						maxLength: AddressMaxLength.neighborhood,
					}}
				/>
			</DialogContent>
			<DialogActions style={{ marginTop: '24px' }}>
				<Button onClick={() => setOpen(false)} color="secondary">
					Cancelar
				</Button>
				<Button onClick={onSave}>Salvar</Button>
			</DialogActions>
		</Dialog>
	);
}
