import { BaseRepository } from './base_repository';

const SESSION_DURATION_IN_MINUTES = 30;

export class SessionRepository extends BaseRepository {
	clearSession(): void {
		sessionStorage.clear();
	}

	startSession(token: string): void {
		this.clearSession();
		const expiration = new Date();
		expiration.setMinutes(
			expiration.getMinutes() + SESSION_DURATION_IN_MINUTES
		);
		sessionStorage.setItem('token', token);
		sessionStorage.setItem('sessionExpiration', expiration.toString());
	}
}
