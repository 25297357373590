import { Location } from 'react-router-dom';

export const removeUrlParameter = (location: Location, param: string): void => {
	const deleteRegex = new RegExp(`${param}=`);
	const params = location.search.slice(1).split('&');
	let search = [];
	for (let i = 0; i < params.length; i++)
		if (deleteRegex.test(params[i]) === false) search.push(params[i]);
	const url =
		location.pathname +
		(search.length ? '?' + search.join('&') : '') +
		location.hash;
	window.history.replaceState({}, document.title, url);
};
