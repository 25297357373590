import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const EmptyBag = () => {
	const navigate = useNavigate();

	return (
		<Box
			display="flex"
			flexDirection="column"
			justifyContent="center"
			alignItems="center"
			height="80vh"
		>
			<Typography variant="h5">A sacola está vazia!</Typography>
			<Button onClick={() => navigate('/')} style={{ marginTop: '20px' }}>
				Voltar para o cardápio
			</Button>
		</Box>
	);
};
