import {
	Box,
	FormControl,
	FormControlLabel,
	Radio,
	RadioGroup,
	Typography,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { EstablishmentAppBar } from '../../../../shared/components';
import { useBag, useEstablishment } from '../../../../shared/contexts';
import {
	PaymentMethod,
	paymentMethodLabels,
} from '../../../../shared/entities';
import { BagFooter } from '../../components';

export function PaymentMethodPage() {
	const navigate = useNavigate();
	const { state, setPaymentMethod } = useBag();
	const establishmentConfig = useEstablishment().state.config;
	const paymentMethods = Object.values(PaymentMethod);

	const nextStep = () => {
		if (!state.paymentMethod) return;
		if (state.paymentMethod === PaymentMethod.cash) {
			return navigate('/bag/payment/change');
		}
		if (
			establishmentConfig.requestDocument &&
			state.deliveryMethod === 'delivery'
		) {
			return navigate('/bag/customer-document');
		}
		navigate('/bag/confirm');
	};

	return (
		<>
			<EstablishmentAppBar />
			<Box padding="20px">
				<Typography variant="h5">Selecione a forma de pagamento</Typography>
				<Box
					padding="20px"
					bgcolor="grey.100"
					marginTop="25px"
					borderRadius="8px"
				>
					<FormControl fullWidth={true}>
						<RadioGroup
							name="payment-method-group"
							value={state.paymentMethod}
							onChange={(e) =>
								setPaymentMethod(e.target.value as PaymentMethod)
							}
						>
							{paymentMethods.map((method) => (
								<FormControlLabel
									key={method}
									value={method}
									control={<Radio />}
									label={paymentMethodLabels[method]}
									style={{ margin: '8px 0' }}
								/>
							))}
						</RadioGroup>
					</FormControl>
				</Box>
				<BagFooter total={state.total} onNext={nextStep} />
			</Box>
		</>
	);
}
