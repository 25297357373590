import { Alert, Button, Typography } from '@mui/material';
import { initializeApp } from 'firebase/app';
import {
	deleteToken,
	getMessaging,
	getToken,
	onMessage,
} from 'firebase/messaging';
import { useSnackbar } from 'notistack';
import {
	createContext,
	ReactNode,
	useContext,
	useEffect,
	useState,
} from 'react';
import { isIOS } from '../device_detector';
import { useDidUpdate } from '../hooks/did_update';
import { UserRepository } from '../repositories';
import { useUser } from './UserContext';

type Notification = {
	state: {
		isNotificationFeatureEnabled: boolean;
		permission: NotificationPermission | null;
	};
	enableNotifications: () => Promise<void>;
};

const DEFAULT_VALUE = {
	state: {
		isNotificationFeatureEnabled:
			!isIOS() &&
			!!window.Notification &&
			!!window.PushManager &&
			!!window.ServiceWorker,
		permission: null,
	},
	enableNotifications: async () => {},
};

const NotificationContext = createContext<Notification>(DEFAULT_VALUE);

export const useNotification = () => useContext(NotificationContext);

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

type Props = {
	userRepository: UserRepository;
	children: ReactNode;
};

export const NotificationContextProvider = ({
	userRepository,
	children,
}: Props) => {
	const app = initializeApp(firebaseConfig);
	const [state, setState] = useState(DEFAULT_VALUE.state);
	const { state: userState } = useUser();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	useEffect(() => {
		if (!state.isNotificationFeatureEnabled) return;
		setState({
			...state,
			permission: Notification.permission as any,
		});

		onMessage(getMessaging(app), (payload) => {
			const { notification } = payload;
			if (notification) {
				enqueueSnackbar({
					persist: true,
					style: {
						backgroundColor: 'rgb(237, 247, 237)',
						width: '100%',
					},
					action: (key: any) => {
						return (
							<Button
								sx={{ margin: '0 16px 8px 0' }}
								onClick={() => closeSnackbar(key)}
							>
								Fechar
							</Button>
						);
					},
					message: (
						<Alert icon={false} sx={{ width: '100%' }}>
							<Typography variant="h6">{notification?.title}</Typography>
							<Typography variant="body1">{notification?.body}</Typography>
						</Alert>
					),
				});
			}
		});

		navigator.permissions
			.query({ name: 'notifications' })
			.then((permission) => {
				permission.addEventListener('change', () => {
					setState({
						...state,
						permission:
							permission.state === 'prompt'
								? 'default'
								: (permission.state as any),
					});
				});
			});

		if (userState.isLogged) {
			loadFcmToken();
		}
	}, []);

	useDidUpdate(() => {
		if (!state.isNotificationFeatureEnabled) return;
		if (userState.isLogged) {
			loadFcmToken();
		} else {
			deleteToken(getMessaging(app))
				.then((success) => console.log('FCM Token deleted: ' + success))
				.catch((error) => console.error('Fail to delete FCM token. ', error));
		}
	}, [userState.isLogged]);

	async function loadFcmToken(): Promise<void> {
		try {
			if (Notification.permission === 'granted') {
				const swr = await registerSW();
				const token = await getToken(getMessaging(app), {
					vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
					serviceWorkerRegistration: swr,
				});
				await userRepository.saveFcmToken(token);
			}
		} catch (error) {
			console.error('An error occurred while retrieving FCM token. ', error);
			throw error;
		}
	}

	async function registerSW(): Promise<ServiceWorkerRegistration> {
		if (!navigator.serviceWorker) {
			throw new Error('This browser does not support Service Worker');
		}
		const registrations = await navigator.serviceWorker.getRegistrations();
		if (registrations.length > 0) {
			return registrations[0];
		}
		try {
			const registration = await navigator.serviceWorker.register(
				'/firebase-messaging-sw.js'
			);
			await navigator.serviceWorker.ready;
			return registration;
		} catch (error) {
			console.error(
				'An error occurred while registering Service Worker. ',
				error
			);
			throw error;
		}
	}

	async function enableNotifications(): Promise<void> {
		if (!state.isNotificationFeatureEnabled) return;
		try {
			if (!window.Notification) {
				console.log('This browser does not support desktop notification');
			} else if (Notification.permission === 'granted') {
				await loadFcmToken();
			} else if (Notification.permission !== 'denied') {
				const permission = await Notification.requestPermission();
				if (permission === 'granted') {
					await loadFcmToken();
				}
			}
		} catch (error) {
			console.error('An error occurred while requesting permission. ', error);
			throw error;
		}
	}

	return (
		<NotificationContext.Provider value={{ enableNotifications, state }}>
			{children}
		</NotificationContext.Provider>
	);
};
