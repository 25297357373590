import { CloseOutlined } from '@mui/icons-material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { FormControl, Input, InputAdornment } from '@mui/material';
import { useEffect, useState } from 'react';

export function SearchField({
	searchTerm,
	setSearchTerm,
}: {
	searchTerm: string;
	setSearchTerm: (term: string) => void;
}) {
	const [debouncedValue, setDebouncedValue] = useState('');

	const handleChange = (e: string) => {
		setDebouncedValue(e);
	};

	useEffect(() => {
		const timer = setTimeout(() => setSearchTerm(debouncedValue), 500);
		return () => clearTimeout(timer);
	}, [debouncedValue, 500]);

	const clearSearch = () => {
		setDebouncedValue('');
		setSearchTerm('');
	};

	return (
		<FormControl fullWidth={true} variant="standard">
			<Input
				placeholder="Buscar no cardápio"
				onChange={(e) => handleChange(e.target.value)}
				style={{ height: '50px' }}
				value={debouncedValue}
				endAdornment={
					<InputAdornment position="end">
						{debouncedValue.length === 0 ? (
							<SearchOutlinedIcon />
						) : (
							<CloseOutlined onClick={clearSearch} />
						)}
					</InputAdornment>
				}
			/>
		</FormControl>
	);
}
