import { City } from './delivery_address';

export enum AddressMaxLength {
	street = 60,
	number = 10,
	complement = 40,
	neighborhood = 30,
}

export class Address {
	constructor(
		public zipCode: string,
		public street: string,
		public neighborhood: string,
		public city: City,
		public state: string,
		public number?: string,
		public complement?: string
	) {}

	static fromJson(json: any): Address {
		return new Address(
			json.zipCode,
			json.street?.substring(0, AddressMaxLength.street),
			json.neighborhood?.substring(0, AddressMaxLength.neighborhood),
			{ code: json.cityCode, name: json.cityName },
			json.state,
			json.number?.substring(0, AddressMaxLength.number),
			json.complement?.substring(0, AddressMaxLength.complement)
		);
	}

	static fromViaCEP(json: any): Address {
		return new Address(
			json.cep,
			json.logradouro?.substring(0, AddressMaxLength.street),
			json.bairro?.substring(0, AddressMaxLength.neighborhood),
			{ code: json.ibge, name: json.localidade },
			json.uf
		);
	}

	get isGeneralZipCode(): boolean {
		return !this.street.trim().length || !this.neighborhood.trim().length;
	}
}
