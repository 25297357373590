import { TextField } from '@mui/material';
// @ts-ignore
import IntlCurrencyInput from 'react-intl-currency-input';

export function CurrencyInput({
	value,
	error,
	onChange,
}: {
	value: number;
	error?: string | null;
	onChange: (value: number) => void;
}) {
	return (
		<IntlCurrencyInput
			component={TextField}
			currency="BRL"
			value={value}
			config={currencyConfig}
			onChange={(_: any, value: number, __: any) => onChange(value)}
			max={999999}
			error={(error ?? '').trim().length > 0}
			helperText={error?.trim()}
			fullWidth={true}
			style={{ marginTop: '25px' }}
			variant="standard"
		/>
	);
}

const currencyConfig = {
	locale: 'pt-BR',
	formats: {
		number: {
			BRL: {
				style: 'currency',
				currency: 'BRL',
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
			},
		},
	},
};
