import { Typography } from '@mui/material';
import { useEstablishment } from '../contexts';

export const BlockedCustomer = () => {
	const { establishment } = useEstablishment().state;

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				flexDirection: 'column',
				alignItems: 'center',
				height: '100vh',
			}}
		>
			<Typography variant="subtitle1" align="center">
				Você possui pendências com o estabelecimento{' '}
				<strong>{establishment?.name}</strong>.<br />
				Entre em contato com o estabelecimento para regularizar sua situação.{' '}
				<br />
				{establishment.phone && establishment?.phone.toCellPhone()}
			</Typography>
		</div>
	);
};
