import { Additional } from '../entities';

export class AdditionalQuantity {
	constructor(public additional: Additional, public quantity: number) {}

	static fromJson(json: any): AdditionalQuantity {
		return new AdditionalQuantity(
			Additional.fromJson(json.additional),
			json.quantity
		);
	}

	static fromTuple(tuple: [Additional, number]): AdditionalQuantity {
		return new AdditionalQuantity(tuple[0], tuple[1]);
	}

	get total(): number {
		return this.additional.price * this.quantity;
	}
}
