import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useBag, useEstablishment } from '../../../../shared/contexts';
import { DeliveryAddress } from '../../../../shared/entities/delivery_address';
import { DeliveryConfig } from '../../../../shared/entities/delivery_config';
import {
	DeliveryDistrict,
	District,
	Region,
} from '../../../../shared/entities/district';
import { ZipCodeNotFoundError } from '../../../../shared/errors';
import { useDidUpdate } from '../../../../shared/hooks/did_update';
import { EstablishmentRepository } from '../../../../shared/repositories';
import { AddressRepository } from '../../../../shared/repositories/address_repository';
import { Validator } from '../../../../shared/validator';
import {
	getDeliveryFromDistrict,
	getDeliveryFromRegion,
} from '../../delivery_availability';
import { deliveryErrors } from '../../delivery_errors';

export enum ZipCodeSearchState {
	IDLE = 'idle',
	LOADING = 'loading',
	SEARCHED = 'searched',
}

type Type = {
	state: {
		deliveryConfig: DeliveryConfig;
		deliveryDistricts: DeliveryDistrict[];
		isUnregisteredDistrict: boolean;
		name: { value: string; error: string };
		zipCode: { value: string; error: string };
		street: { value: string; error: string };
		number: { value: string; error: string };
		complement: string;
		district: { value: District | null; error: string };
		neighborhood: { value: string; error: string };
		region: { value: Region | null; error: string };
		city: { code: string; name: string };
		state: string;
		isGeneralZipCode: boolean;
		zipCodeSearchState: ZipCodeSearchState;
		deliveryFee: number | null;
		minOrderValue: number | null;
		deliveryAvailabilityError: string;
		minOrderValueError: string;
	};
	actions: {
		onNameChange: (value: string) => void;
		onZipCodeChange: (value: string) => void;
		onStreetChange: (value: string) => void;
		onNumberChange: (value: string) => void;
		onComplementChange: (value: string) => void;
		onDistrictChange: (value: District) => void;
		onBlurDistrict: () => void;
		onNeighborhoodChange: (value: string) => void;
		onRegionChange: (value: Region) => void;
		onBlurRegion: () => void;
		onCityChange: (code: string, value: string) => void;
		onStateChange: (value: string) => void;
		validate: () => boolean;
		toEntity: () => DeliveryAddress;
		fromEntity: (entity: DeliveryAddress) => void;
		loadDistricts: () => District[];
		removeNeighborhood: () => void;
	};
};

const DEFAULT_VALUE = {
	state: {
		deliveryConfig: null as unknown as DeliveryConfig,
		deliveryDistricts: [] as DeliveryDistrict[],
		isUnregisteredDistrict: false,
		name: { value: '', error: '' },
		zipCode: { value: '', error: '' },
		street: { value: '', error: '' },
		number: { value: '', error: '' },
		complement: '',
		district: { value: null as District | null, error: '' },
		neighborhood: { value: '', error: '' },
		region: { value: null as Region | null, error: '' },
		city: { code: '', name: '' },
		state: '',
		isGeneralZipCode: false,
		zipCodeSearchState: ZipCodeSearchState.IDLE,
		deliveryFee: null as number | null,
		minOrderValue: null as number | null,
		deliveryAvailabilityError: '',
		minOrderValueError: '',
	},
	actions: {
		onNameChange: () => {},
		onZipCodeChange: () => {},
		onStreetChange: () => {},
		onNumberChange: () => {},
		onComplementChange: () => {},
		onDistrictChange: () => {},
		onBlurDistrict: () => {},
		onNeighborhoodChange: () => {},
		onRegionChange: () => {},
		onBlurRegion: () => {},
		onCityChange: () => {},
		onStateChange: () => {},
		validate: () => false,
		toEntity: () => undefined as any,
		fromEntity: () => {},
		loadDistricts: () => [],
		removeNeighborhood: () => {},
	},
};

const DeliveryAddressContext = React.createContext<Type>(DEFAULT_VALUE);

export const useDeliveryAddress = (): Type =>
	useContext(DeliveryAddressContext);

type Props = {
	establishmentRepository: EstablishmentRepository;
	addressRepository: AddressRepository;
	children: ReactNode;
};

export const DeliveryAddressContextProvider = ({
	children,
	addressRepository,
	establishmentRepository,
}: Props) => {
	const { config } = useEstablishment().state;
	const { state: bagState } = useBag();
	const [addressId, setAddressId] = useState<number | string | undefined>();
	const [state, setState] = useState(DEFAULT_VALUE.state);
	const validators = {
		name: new Validator().required().minLength(3),
		zipCode: new Validator().required().zipCode(),
		number: new Validator().required(),
		street: new Validator().required(),
		district: new Validator().required(),
		neighborhood: new Validator().required(),
		region: new Validator().required(),
	};

	useEffect(() => {
		Promise.all([
			establishmentRepository.getDeliveryConfig(),
			establishmentRepository.getDeliveryDistrics(),
		]).then(([deliveryConfig, deliveryDistricts]) => {
			setState({ ...state, deliveryConfig, deliveryDistricts });
		});
	}, []);

	const fromEntity = (entity: DeliveryAddress) => {
		setAddressId(entity.id!);
		setState({
			...state,
			name: { value: entity.name, error: '' },
			zipCode: { value: entity.zipCode, error: '' },
			street: { value: entity.street, error: '' },
			number: { value: entity.number ?? '', error: '' },
			complement: entity.complement ?? '',
			neighborhood: { value: entity.neighborhood, error: '' },
			city: { code: entity.city.code, name: entity.city.name },
			state: entity.state,
			zipCodeSearchState: ZipCodeSearchState.SEARCHED,
		});
	};

	const onNameChange = (value: string) => {
		setState({
			...state,
			name: { value, error: validators.name.execute(value) },
		});
	};

	const onZipCodeChange = (value: string) => {
		setState({
			...state,
			zipCodeSearchState: ZipCodeSearchState.IDLE,
			street: { value: '', error: '' },
			neighborhood: { value: '', error: '' },
			city: { code: '', name: '' },
			state: '',
			district: { value: null, error: '' },
			region: { value: null, error: '' },
			complement: '',
			isUnregisteredDistrict: false,
			number: { value: '', error: '' },
			isGeneralZipCode: false,
			zipCode: { value, error: validators.zipCode.execute(value) },
			deliveryAvailabilityError: '',
			minOrderValueError: '',
			deliveryFee: null,
			minOrderValue: null,
		});
	};

	useDidUpdate(() => {
		if (
			!state.zipCode.error.length &&
			state.zipCodeSearchState === ZipCodeSearchState.IDLE
		) {
			fillAddressByZipCode(state.zipCode.value);
		}
	}, [state.zipCode]);

	const onStreetChange = (value: string) => {
		setState({
			...state,
			street: { value, error: validators.street.execute(value) },
		});
	};

	const onNumberChange = (value: string) => {
		setState({
			...state,
			number: { value, error: validators.number.execute(value) },
		});
	};

	const onComplementChange = (value: string) => {
		setState({ ...state, complement: value });
	};

	const onDistrictChange = (value: District) => {
		let deliveryFee = null;
		let minOrderValue = null;
		let minOrderValueError = '';
		let deliveryAvailabilityError = '';
		if (!config.chargeDeliveryTax) {
			deliveryFee = 0;
			minOrderValue = 0;
		} else if (value) {
			const result = getDeliveryFromDistrict(
				value,
				bagState.total,
				state.deliveryConfig
			);
			deliveryFee = result?.deliveryFee ?? null;
			minOrderValue = result?.minOrderValue ?? null;
			switch (result?.error?.type) {
				case 'deliveryUnavailable':
					deliveryAvailabilityError = result.error.message;
					break;
				case 'minOrderValue':
					minOrderValueError = result.error.message;
					break;
			}
		}

		setState({
			...state,
			district: { value, error: '' },
			neighborhood: { value: '', error: '' },
			region: { value: null, error: '' },
			deliveryAvailabilityError: deliveryAvailabilityError,
			minOrderValueError: minOrderValueError,
			deliveryFee: deliveryFee,
			minOrderValue: minOrderValue,
		});
	};

	const onBlurDistrict = () => {
		if (
			!state.district.value &&
			!state.deliveryConfig.deliverOnUnregisteredRegion &&
			loadDistricts().length > 0
		) {
			setState({
				...state,
				district: {
					value: null,
					error:
						'Apenas bairros cadastrados pelo estabelecimento são permitido. Selecione um bairro da lista.',
				},
			});
		}
	};

	const onNeighborhoodChange = (value: string) => {
		setState({
			...state,
			neighborhood: { value, error: validators.neighborhood.execute(value) },
		});
	};

	const onRegionChange = (value: Region) => {
		let deliveryAvailabilityError = '';
		let deliveryFee = null;
		let minOrderValue = null;
		let minOrderValueError = '';
		if (!config.chargeDeliveryTax) {
			deliveryFee = 0;
			minOrderValue = 0;
		} else if (value) {
			const result = getDeliveryFromRegion(value, bagState.total);
			deliveryFee = result.deliveryFee ?? null;
			minOrderValue = result.minOrderValue ?? null;
			switch (result.error?.type) {
				case 'deliveryUnavailable':
					deliveryAvailabilityError = result.error.message;
					break;
				case 'minOrderValue':
					minOrderValueError = result.error.message;
					break;
			}
		}

		setState({
			...state,
			region: { value, error: '' },
			deliveryAvailabilityError,
			minOrderValueError,
			deliveryFee,
			minOrderValue,
		});
	};

	const onBlurRegion = () => {
		if (!state.region.value) {
			setState({
				...state,
				region: {
					value: null,
					error:
						'Apenas referências cadastradas são permitidas. Selecione uma referência da lista',
				},
			});
		}
	};

	const onCityChange = (code: string, value: string) => {
		setState({ ...state, city: { code, name: value } });
	};

	const onStateChange = (value: string) => {
		setState({ ...state, state: value });
	};

	function loadDistricts(): District[] {
		return state.deliveryDistricts
			.filter((d) => d.city.code === state.city.code)
			.map((d) => d.districts)
			.flat();
	}

	function removeNeighborhood() {
		setState({
			...state,
			district: { value: null, error: '' },
			neighborhood: { value: '', error: '' },
			region: { value: null, error: '' },
		});
	}

	async function fillAddressByZipCode(zipCode: string) {
		try {
			setState({ ...state, zipCodeSearchState: ZipCodeSearchState.LOADING });
			const address = await addressRepository.getAddressByZipCode(zipCode);
			let deliveryAvailabilityError = '';
			let deliveryFee = null;
			let minOrderValue = null;
			let isUnregisteredDistrict = false;
			let neighborhood = '';
			const district = state.deliveryDistricts.find(
				(item) => item.city.code === address.city.code
			);
			if (!district) {
				if (state.deliveryConfig.acceptOrderFromDifferentCity) {
					deliveryFee = state.deliveryConfig.defaultDeliveryFee;
					minOrderValue = state.deliveryConfig.defaultMinimumOrderValue;
					isUnregisteredDistrict = true;
					neighborhood = address.neighborhood;
				} else {
					deliveryAvailabilityError =
						deliveryErrors.unavailableDeliveryOnCity.message;
				}
			}

			setState({
				...state,
				zipCodeSearchState: ZipCodeSearchState.SEARCHED,
				street: { value: address.street, error: '' },
				city: address.city,
				state: address.state,
				isGeneralZipCode: address.isGeneralZipCode,
				deliveryAvailabilityError: deliveryAvailabilityError,
				deliveryFee: deliveryAvailabilityError ? null : deliveryFee,
				minOrderValue: deliveryAvailabilityError ? null : minOrderValue,
				isUnregisteredDistrict: isUnregisteredDistrict,
				neighborhood: neighborhood
					? {
							value: neighborhood,
							error: validators.neighborhood.execute(neighborhood),
					  }
					: state.neighborhood,
			});
		} catch (error) {
			setState({
				...state,
				zipCodeSearchState: ZipCodeSearchState.IDLE,
				zipCode: {
					...state.zipCode,
					error:
						error instanceof ZipCodeNotFoundError
							? 'CEP não encontrado'
							: 'Algo de errado aconteceu. Tente novamente em breve',
				},
			});
			console.log('Fail to load address by zipcode', error);
		}
	}

	const validate = () => {
		const nameError = validators.name.execute(state.name.value);
		const zipCodeError = validators.zipCode.execute(state.zipCode.value);
		const streetError = validators.street.execute(state.street.value);
		const numberError = validators.number.execute(state.number.value);
		const district = state.district?.value;
		const districtError = state.isUnregisteredDistrict
			? ''
			: validators.district.execute(district?.neighborhood ?? '');
		const neighborhoodError = district?.isUnregistered
			? validators.neighborhood.execute(state.neighborhood.value)
			: '';
		const regionError = district?.regions?.length
			? validators.region.execute(state.region.value?.name ?? '')
			: '';
		setState({
			...state,
			name: { ...state.name, error: nameError },
			zipCode: { ...state.zipCode, error: zipCodeError },
			street: { ...state.street, error: streetError },
			district: { ...state.district, error: districtError },
			neighborhood: { ...state.neighborhood, error: neighborhoodError },
			region: { ...state.region, error: regionError },
			number: { ...state.number, error: numberError },
		});
		return (
			!state.deliveryAvailabilityError.trim().length &&
			!nameError.trim().length &&
			!zipCodeError.trim().length &&
			!streetError.trim().length &&
			!numberError.trim().length &&
			!districtError.trim().length &&
			!regionError.trim().length &&
			!neighborhoodError.trim().length &&
			state.city.code.trim().length > 0 &&
			state.city.name.trim().length > 0 &&
			state.state.trim().length > 0
		);
	};

	const toEntity = () => {
		const district = state.district.value;
		const isUnregisteredDistrict =
			district?.isUnregistered || state.isUnregisteredDistrict;
		return new DeliveryAddress(
			state.name.value,
			state.zipCode.value,
			state.street.value,
			state.number.value,
			state.complement,
			isUnregisteredDistrict
				? state.neighborhood.value
				: district!.neighborhood,
			state.city,
			state.state,
			!addressId,
			state.region.value?.name,
			false,
			addressId
		);
	};

	return (
		<DeliveryAddressContext.Provider
			value={{
				state,
				actions: {
					onNameChange,
					onZipCodeChange,
					onStreetChange,
					onNumberChange,
					onComplementChange,
					onDistrictChange,
					onNeighborhoodChange,
					onCityChange,
					onStateChange,
					validate,
					toEntity,
					fromEntity,
					loadDistricts,
					onRegionChange,
					removeNeighborhood,
					onBlurDistrict,
					onBlurRegion,
				},
			}}
		>
			{children}
		</DeliveryAddressContext.Provider>
	);
};
