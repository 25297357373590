import { List } from '@mui/material';
import { Box } from '@mui/system';
import { useBag } from '../../../shared/contexts/BagContext';
import { ProductTile } from './ProductTile';

export function Products({ canRemove }: { canRemove: boolean }) {
	const { state } = useBag();

	return (
		<Box sx={{ width: '100%', marginBottom: '10px' }}>
			<List>
				{state.products.map((product, index) => {
					const isLast = index === state.products.length - 1;
					return (
						<ProductTile
							key={index}
							product={product}
							index={index}
							isLast={isLast}
							canRemove={canRemove}
						/>
					);
				})}
			</List>
		</Box>
	);
}
