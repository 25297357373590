export enum UserMaxLength {
	name = 60,
}

export class User {
	constructor(
		public name: string,
		public phone: string,
		public document: string,
		public id?: number
	) {}

	static fromJson(json: any) {
		return new User(json.name, json.phone, json.document, json.id);
	}

	get completed() {
		return !!this.name;
	}

	get firstName() {
		return this.name.split(' ')[0];
	}
}
