import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import RestaurantMenuOutlinedIcon from '@mui/icons-material/RestaurantMenuOutlined';
import { BottomNavigation, BottomNavigationAction, Paper } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { useEstablishment } from '../../shared/contexts/EstablishmentContext';
import { ProductRepository, WaiterRepository } from '../../shared/repositories';
import { MenuPage } from '../menu';
import { WaiterPage } from '../waiter';
import { HomeAppBar } from './components/HomeAppBar';

type Props = {
	productRepository: ProductRepository;
	waiterRepository: WaiterRepository;
};

export function HomePage({ productRepository, waiterRepository }: Props) {
	const [pageIndex, setPageIndex] = useState<number>(0);
	const { establishment } = useEstablishment().state;

	function getPage(index: number): JSX.Element {
		switch (index) {
			case 0:
				return <MenuPage productRepository={productRepository} />;
			default:
				return <WaiterPage waiterRepository={waiterRepository} />;
		}
	}

	return (
		<>
			<HomeAppBar />
			<Box sx={{ pb: 7 }}>
				{getPage(pageIndex)}
				{establishment?.isSelfService && (
					<Paper
						sx={{
							position: 'fixed',
							bottom: 0,
							left: 0,
							right: 0,
							height: '55px',
						}}
						elevation={2}
					>
						<BottomNavigation
							showLabels
							value={pageIndex}
							onChange={(_, newValue) => setPageIndex(newValue)}
						>
							<BottomNavigationAction
								label="Cardápio"
								icon={<RestaurantMenuOutlinedIcon />}
							/>
							<BottomNavigationAction
								label="Garçom"
								icon={<PersonOutlinedIcon />}
							/>
						</BottomNavigation>
					</Paper>
				)}
			</Box>
		</>
	);
}
