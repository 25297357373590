import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogTitle,
	IconButton,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useBag } from '../../../shared/contexts';
import { ProductSelection } from '../product_selection';

type Props = {
	product: ProductSelection;
	index: number;
	isLast: boolean;
	canRemove: boolean;
};

export const ProductTile = ({ product, index, isLast, canRemove }: Props) => {
	const [open, setOpen] = useState(false);

	return (
		<Box marginBottom={isLast ? 0 : '10px'}>
			<Box display="flex" justifyContent="space-between" alignItems="center">
				<Box display="flex" alignItems="center" marginRight="12px">
					<Typography variant="subtitle2" marginRight="5px" fontWeight="bold">
						{product.quantity}x
					</Typography>
					<Typography variant="subtitle2" fontWeight="bold" align="left">
						{product.name}
					</Typography>
				</Box>
				<Box display="flex" alignItems="center">
					<Typography variant="subtitle2" fontWeight="bold" marginRight="5px">
						{(product.price * product.quantity).toCurrency()}
					</Typography>
					{canRemove && (
						<IconButton onClick={() => setOpen(true)}>
							<RemoveOutlinedIcon color="error" />
						</IconButton>
					)}
				</Box>
			</Box>
			<Box padding={!canRemove ? '0 5px 0 20px' : '0 45px 0 22px'}>
				{Array.from(product.additionals).map(({ additional, quantity }) => {
					return (
						<Box
							display="flex"
							key={additional.id}
							justifyContent="space-between"
						>
							<Typography variant="body2" fontWeight="600">
								{quantity} - {additional.name}
							</Typography>
							<Typography variant="body2" fontWeight="600">
								{(additional.price * quantity).toCurrency()}
							</Typography>
						</Box>
					);
				})}
				{product.observations && (
					<Typography variant="body2" align="left" marginTop="3px">
						Obs: {product.observations}
					</Typography>
				)}
			</Box>
			<RemoveProductDialog
				open={open}
				close={() => setOpen(false)}
				index={index}
			/>
		</Box>
	);
};

function RemoveProductDialog({
	open,
	close,
	index,
}: {
	open: boolean;
	close: (open: boolean) => void;
	index: number;
}) {
	const { removeProduct } = useBag();

	const handleClose = (remove: boolean) => {
		close(false);
		if (remove) {
			removeProduct(index);
		}
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>Deseja remover este produto?</DialogTitle>
			<DialogActions>
				<Button onClick={() => handleClose(false)}>Não</Button>
				<Button onClick={() => handleClose(true)}>Sim</Button>
			</DialogActions>
		</Dialog>
	);
}
