import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import { UserContextProvider } from './shared/contexts';
import { BagContextProvider } from './shared/contexts/BagContext';
import { EstablishmentContextProvider } from './shared/contexts/EstablishmentContext';
import { NotificationContextProvider } from './shared/contexts/NotificationContext';
import {
	authRepository,
	bagRepository,
	customerRepository,
	establishmentRepository,
	sessionRepository,
	userRepository,
} from './shared/dependencies';
import './shared/extensions';
import { AppRoutes } from './shared/routes';
import { theme } from './shared/theme';
import { WebViewRedirect } from './shared/webview_redirect';

const rootElement = document.getElementById('root');

ReactDOM.render(
	<ThemeProvider theme={theme}>
		<BrowserRouter>
			<WebViewRedirect>
				<SnackbarProvider
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center',
					}}
					style={{ width: '100%', padding: '0' }}
				>
					<UserContextProvider
						authRepository={authRepository}
						userRepository={userRepository}
					>
						<NotificationContextProvider userRepository={userRepository}>
							<EstablishmentContextProvider
								sessionRepository={sessionRepository}
								establishmentRepository={establishmentRepository}
								customerRepository={customerRepository}
							>
								<BagContextProvider bagRepository={bagRepository}>
									<AppRoutes />
								</BagContextProvider>
							</EstablishmentContextProvider>
						</NotificationContextProvider>
					</UserContextProvider>
				</SnackbarProvider>
			</WebViewRedirect>
		</BrowserRouter>
	</ThemeProvider>,
	rootElement
);
