export abstract class AppError {
	constructor(public readonly message: string) {}
}

export class UnexpectedError extends AppError {
	constructor() {
		super('Algo de errado aconteceu. Tente novamente em breve');
	}
}

export class EstablishmentNotFoundError extends AppError {
	constructor() {
		super('Estabelecimento não encontrado');
	}
}

export class UnauthorizedError extends AppError {
	constructor() {
		super('Não autorizado');
	}
}

export class SelectRequiredAdditionalsError extends AppError {
	constructor() {
		super('Selecione os adicionais obrigatórios');
	}
}

export class InvalidOrExpiredTokenError extends AppError {
	constructor() {
		super('Código inválido ou expirado');
	}
}

export class SessionExpiredError extends AppError {
	constructor() {
		super('Sessão expirada');
	}
}

export class EstablishmentClosedError extends AppError {
	constructor() {
		super('Estabelecimento fechado');
	}

	get description() {
		return 'O estabelecimento está fechado no momento. Tente novamente mais tarde.';
	}
}

export class BlockedCustomerError extends AppError {
	constructor() {
		super('Cliente bloqueado');
	}

	get description() {
		return 'Você possui pendências com o estabelecimento. Entre em contato com o estabelecimento para regularizar sua situação.';
	}
}

export class ZipCodeNotFoundError extends AppError {
	constructor() {
		super('CEP não encontrado');
	}
}
