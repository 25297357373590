export type DeliveryErrorType =
	| 'minOrderValue'
	| 'requireRegion'
	| 'deliveryUnavailable';

export type DeliveryError = {
	type: DeliveryErrorType;
	message: string;
};

export const deliveryErrors = {
	unavailableDeliveryOnCity: {
		type: 'deliveryUnavailable',
		message: 'Este estabelecimento não realiza entregas para esta cidade',
	} as DeliveryError,
	unavailableDeliveryOnNeighborhood: {
		type: 'deliveryUnavailable',
		message: 'Este estabelecimento não realiza entregas neste bairro',
	} as DeliveryError,
	unavailableDeliveryOnRegion: {
		type: 'deliveryUnavailable',
		message: 'Este estabelecimento não realiza entregas nesta região',
	} as DeliveryError,
	requireRegion: {
		type: 'requireRegion',
		message: 'Selecione uma referência para este bairro',
	} as DeliveryError,
	minOrderValue: (minOrderValue: number) =>
		({
			type: 'minOrderValue',
			message: `O pedido mínimo neste estabelecimento é de ${minOrderValue.toCurrency()} sem contar com a taxa de entrega`,
		} as DeliveryError),
};
